import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ExecutorFilter} from '../../../util/executor-filter';
import {FilterSearch} from '../../model/filtro.model';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'filter-user',
    templateUrl: './filter-user-dialog.html',
    styleUrls: ['./filter-user-dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class FilterUserDialog implements OnInit {

    executorFilter: ExecutorFilter;
    currentFilters: Array<FilterSearch>;

    name: string;
    email: string;
    cpf: string;
    phone: string;
    ownReferralCode: string;

    franchiseeControl: FormControl = new FormControl({disabled: false});
    franchiseeIdSelected: number;

    constructor(private _dialogRef: MatDialogRef<FilterUserDialog>) {
    }

    ngOnInit(): void {
        this.currentFilters.forEach(filterSearch => {
            if (filterSearch.key == 'franchisee.id') {
                this.franchiseeIdSelected = filterSearch.value;
            }
        });
    }
    executeFilter(): void {
        const filters = new Array<FilterSearch>();

        if (this.franchiseeIdSelected) {
            filters.push(new FilterSearch('franchisee.id', this.franchiseeIdSelected));
        }

        if (this.cpf) {
            filters.push(new FilterSearch('cpf', this.cpf));
        }

        if (this.name) {
            filters.push(new FilterSearch('name', this.name));
        }

        if (this.email) {
            filters.push(new FilterSearch('email', this.email));
        }

        if (this.phone) {
            filters.push(new FilterSearch('phone', this.phone));
        }

        if (this.ownReferralCode) {
            filters.push(new FilterSearch('ownReferralCode', this.ownReferralCode));
        }

        this.executorFilter.executeFilter(filters);

        this.close();
    }

    close(): void {
        this._dialogRef.close();
    }
}
