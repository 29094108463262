<div>
    <div class="logo mb-20" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="90">
            <span class="logo-text h1">Aplicar Filtros</span>
        </div>
        <div fxFlex="10">
            <button mat-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="card-divider mb-0"></div>

    <div fxLayout="column">
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Nome"
                       [(ngModel)]="name"
                       [ngModelOptions]="{standalone: true}">
                <mat-icon matSuffix style="color: gray;">comment</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="email">
                <mat-icon matSuffix style="color: gray;">email</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>CPF</mat-label>
                <input matInput placeholder="CPF"
                       [ngModelOptions]="{standalone: true}"
                       [(ngModel)]="cpf"
                       [dropSpecialCharacters]="false"
                       [mask]="'000.000.000-00'">
                <mat-icon matSuffix style="color: gray;">featured_play_list</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Telefone</mat-label>
                <input matInput placeholder="Telefone"
                       name="phone"
                       [(ngModel)]="phone"
                       [ngModelOptions]="{standalone: true}">
                <mat-icon matSuffix style="color: gray;">contact_phone</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Código Próprio</mat-label>
                <input matInput placeholder="Código Próprio"
                       name="ownReferralCode"
                       [(ngModel)]="ownReferralCode"
                       [ngModelOptions]="{standalone: true}">
                <mat-icon matSuffix style="color: gray;">vpn_key</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="20" *isCurrentUserchibarrogrei="true">
                <mat-label>Licenciado</mat-label>
                <input type="text"
                    placeholder="Licenciado"
                    aria-label="text"
                    matInput
                    [(ngModel)]="franchiseeIdSelected"
                    [formControl]="franchiseeControl"
                    [matAutocomplete]="auto">

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFranchiseeName">
                    <mat-option *ngFor="let franchisee of franchiseesFilteredOptions | async" [value]="franchisee.id">
                        {{franchisee.name}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <button mat-button class="button-filter" (click)="executeFilter()">
                Filtrar
            </button>
        </form>
    </div>
</div>
