import {Injectable} from '@angular/core';
import {PdfComponent} from './pdf.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import {environment} from '../../../environments/environment';
import { ReportRequest, ReportType } from '../model/report-request.model';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { FilterSearch } from '../model/filtro.model';
import { filter } from 'rxjs/operators';
import { createRequestOption } from 'app/util/request-util';

@Injectable({providedIn: 'root'})
export class PdfService {
    private pdfComponent: MatDialogRef<PdfComponent>;

    constructor(private dialog: MatDialog, protected http: HttpClient) {}

    public stop(): void {
        this.pdfComponent.close();
    }

    public start(titulo: string, pdf: string): void {
        this.pdfComponent = this.dialog.open(PdfComponent, {
            autoFocus: false,
            maxHeight: '90vh'
        });

        this.pdfComponent.componentInstance.titulo = titulo;
        this.pdfComponent.componentInstance.pdf = pdf;
        this.pdfComponent.componentInstance.service = this;

        this.pdfComponent.afterClosed()
            .subscribe(result => {
                if (result) {}
                this.pdfComponent = null;
            });
    }

    public downloadAsPdf(url: string, saveAsFileName: string, options: HttpParams = null): void{
        //'api/v1/refrigerator/refrigerators-stock-report-after-recharge'
        var urlFinal = environment.url + url;
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        headers = headers.set('Bearer', localStorage.getItem('token'));

        this.http.get(urlFinal, { params: options, headers: headers, responseType: 'blob' }).subscribe(
            (response: Blob) => saveAs(response, saveAsFileName)
        );        
    }
}
