export enum ROLE {
    CHIBARROGREI = 'CHIBARROGREI',
    USER = 'USER',
}

export class Authority {
    name?: string;
    description?: string;

    constructor(name?: string, description?: string) {
        this.name = name;
        this.description = description;
    }

    static getAuthorities(): Array<Authority> {
        const authorities = new Array<Authority>();
        authorities.push(new Authority(ROLE.CHIBARROGREI, 'ChibarroGrei'));
        return authorities;
    }
}
