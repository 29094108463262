import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ExecutorFilter} from '../../../util/executor-filter';
import {FilterSearch} from '../../model/filtro.model';
import {StatusAnnouncement} from "../../model/announcement.model";
import {CategorieModel} from "../../model/categorie.model";
import {CategorieService} from "../../categorie/categorie.service";

@Component({
    selector: 'filter-announcement',
    templateUrl: './filter-announcement-dialog.component.html',
    styleUrls: ['./filter-announcement-dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class FilterAnnouncementDialog {

    categories?: Array<CategorieModel>;
    categorie?: CategorieModel;
    status: string;
    executorFilter: ExecutorFilter;

    constructor(public _dialogRef: MatDialogRef<FilterAnnouncementDialog>,
                private _categorieService: CategorieService) {
        this._categorieService.findAll().toPromise().then(value => this.categories = value.body);
    }

    executeFilter(): void {
        const filters = new Array<FilterSearch>();

        if (this.status) {
            filters.push(new FilterSearch('status', this.status));
        }

        if (this.categorie) {
            filters.push(new FilterSearch('categorie', this.categorie.title));
        }

        this.executorFilter.executeFilter(filters);
        this.close();
    }

    compareID(one: any, two: any): boolean {
        return one && two ? one.id === two.id : one === two;
    }

    close(): void {
        this._dialogRef.close();
    }
}
