<div id="event" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <div class="center">

        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" (click)="close()" [routerLink]="'/view/event'">
                    <mat-icon style="color: white">arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">

                    <div fxLayout="row" fxLayoutAlign="start center" class="mb-12">
                        <mat-icon class="secondary-text s-24">home</mat-icon>
                        <mat-icon class="secondary-text s-24">chevron_right</mat-icon>
                        <div class="subtitle secondary-text">
                            <span>Eventos</span>
                        </div>
                        <mat-icon class="secondary-text s-24">chevron_right</mat-icon>
                        <div class="subtitle secondary-text">
                            <span>{{this.isUpdate ? 'Editar evento' : 'Salvar evento'}}</span>
                        </div>
                    </div>

                    <div class="h2" *ngIf="this.isUpdate">
                        # {{event.id}} - {{event.title}}
                    </div>

                    <div class="logo mb-24 mb-md-0" *ngIf="!this.isUpdate"
                         fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="logo-icon s-32 mr-16"
                                  [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            event
                        </mat-icon>
                        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            Preencha os dados
                        </span>
                    </div>

                </div>
            </div>

            <button mat-raised-button class="fuse-white mt-24 mt-md-0" (click)="update(event)"
                    *ngIf="this.isUpdate" [disabled]="saveForm.invalid">
                <span>ATUALIZAR</span>
            </button>

            <button mat-raised-button class="fuse-white mt-24 mt-md-0"
                    (click)="save(event)" *ngIf="!this.isUpdate" [disabled]="saveForm.invalid">
                <span>SALVAR</span>
            </button>
        </div>

        <div class="content-card">

            <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

                <form fxLayout="column" class="w-100-p" fxLayoutAlign="start" fxFlex="1 0 auto" name="saveForm"
                      [formGroup]="saveForm">

                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="70">
                                <mat-label>Título</mat-label>
                                <input matInput placeholder="Título"
                                       name="title"
                                       formControlName="title"
                                       [(ngModel)]="event.title"
                                       required>
                                <mat-icon matSuffix style="color: gray;">title</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" fxFlex="30">
                                <mat-label>Período de Evento</mat-label>
                                <mat-date-range-input [rangePicker]="pickerPeriode">
                                    <input matInput name="dateBegin" matStartDate formControlName="dateBegin"
                                           placeholder="Início" required>
                                    <input matInput name="dateEnd" matEndDate formControlName="dateEnd"
                                           placeholder="Término" required>
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="pickerPeriode"></mat-datepicker-toggle>
                                <mat-date-range-picker #pickerPeriode></mat-date-range-picker>

                                <mat-error *ngIf="saveForm.controls.dateBegin.hasError('matStartDateInvalid')">
                                    Data início Inválida
                                </mat-error>

                                <mat-error *ngIf="saveForm.controls.dateEnd.hasError('matEndDateInvalid')">Data
                                    término Inválida
                                </mat-error>

                                <mat-error *ngIf="saveForm.controls.dateBegin.errors?.required">Data início é
                                    obrigatório.
                                </mat-error>

                                <mat-error *ngIf="saveForm.controls.dateEnd.errors?.required">Data término é
                                    obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="33">
                                <mat-label>Usuário</mat-label>
                                <mat-select [(ngModel)]="event.user"
                                            [compareWith]="compareID"
                                            formControlName="user"
                                            placeholder="Usuário" required>
                                    <mat-option *ngFor="let user of users"
                                                [value]="user">{{user.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="33">
                                <mat-label>Status</mat-label>
                                <mat-select [(ngModel)]="event.status" placeholder="Status"
                                            [ngModelOptions]="{standalone: true}">
                                    <mat-option [value]="'EM_ANALISE'">Em Análise</mat-option>
                                    <mat-option [value]="'LIBERADO'">Liberado</mat-option>
                                    <mat-option [value]="'REJEITADO'">Rejeitado</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="31">
                                <mat-label>Contatos</mat-label>
                                <mat-select multiple [compareWith]="compareID" [(ngModel)]="event.contacts"
                                            formControlName="contacts" placeholder="Contatos">
                                    <mat-option *ngFor="let contact of contacts"
                                                [value]="contact">{{contact.pronounTreatment}} {{contact.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <button mat-raised-button class="mb-20"
                                    style="align-content: center; background-color: #960210 !important; color: white !important;"
                                    (click)="openContact()" fxFlex="9">
                                <span>Novo Contato</span>
                            </button>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                                <mat-label>Descrição</mat-label>
                                <textarea matInput placeholder="Descrição"
                                       name="description" rows="5"
                                       formControlName="description"
                                       [(ngModel)]="event.description"
                                       required>
                                </textarea>
                                <mat-icon matSuffix style="color: gray;">description</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                                <mat-label>Imagem</mat-label>
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <img [src]="'data:' + imageContentType + ';base64,' + image"
                                         *ngIf="this.image != null" class="avatar"/>
                                    <img [src]="getImageUrl(event.id)"
                                         *ngIf="event.id != undefined && !isClearImage" class="avatar"/>

                                    <button mat-icon-button matPrefix *ngIf="nomeArquivo == null"
                                            (click)="f_input.click()">
                                        <mat-icon>attach_file</mat-icon>
                                        Selecione...
                                    </button>

                                    <input type="text text-truncate" readonly matInput
                                           formControlName="nomeArquivo" name="nomeArquivo"/>

                                    <input
                                            type="file"
                                            hidden
                                            accept="image/png"
                                            #f_input
                                            (change)="uploadDocument($event)"
                                    />

                                    <button mat-icon-button matSuffix *ngIf="nomeArquivo != null"
                                            (click)="clearFile(); f_input.value=null">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </div>
                                <mat-error *ngIf="saveForm.get('image').hasError('maxContentSize')">
                                    O tamanho total não deve exceder {{maxSize}}MB
                                </mat-error>
                                <mat-error *ngIf="!saveForm.get('image').hasError('maxContentSize')">
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>CEP</mat-label>
                                <input matInput placeholder="CEP"
                                       name="zipCode"
                                       maxlength="10"
                                       formControlName="zipCode"
                                       (keyup)="onKeyUp($event)"
                                       [(ngModel)]="event.address.zipCode"
                                       mask="00000-000">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="65">
                                <mat-label>Endereço</mat-label>
                                <input matInput placeholder="Endereço"
                                       name="street"
                                       formControlName="street"
                                       [(ngModel)]="event.address.street">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" fxFlex="15">
                                <mat-label>Número</mat-label>
                                <input matInput placeholder="Número"
                                       name="number"
                                       formControlName="number"
                                       [(ngModel)]="event.address.number"
                                >
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Estado</mat-label>
                                <mat-select [(ngModel)]="event.address.state" formControlName="state"
                                            placeholder="Estado">
                                    <mat-option [value]="">Nenhum</mat-option>
                                    <mat-option *ngFor="let state of states"
                                                [value]="state.acronym">{{state.name}}</mat-option>
                                </mat-select>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Cidade</mat-label>
                                <input matInput placeholder="Cidade"
                                       name="city"
                                       formControlName="city"
                                       [(ngModel)]="event.address.city">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Bairro</mat-label>
                                <input matInput placeholder="Bairro"
                                       name="district"
                                       formControlName="district"
                                       [(ngModel)]="event.address.district">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" fxFlex="40">
                                <mat-label>Complemento</mat-label>
                                <input matInput
                                       placeholder="Complemento"
                                       formControlName="complement"
                                       [(ngModel)]="event.address.complement"
                                       name="complement">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                            </mat-form-field>
                        </div>

                    </div>

                </form>

            </div>

        </div>

    </div>

</div>
