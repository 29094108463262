<div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
     fxLayoutAlign.gt-sm="space-between center">

    <div fxLayout="row" class="w-100-p" fxLayoutAlign="center center">
        <h1 class="text-center title-bold font-size-30">{{titulo}}</h1>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
        <div class="text-right">
            <button mat-icon-button (click)="stop()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>

<div class="text-center pb-20">

    <button mat-raised-button [disabled]="pagina === 1" class="mat-accent mr-16" (click)="previunsPagina()">
        <mat-icon class="blank">chevron_left</mat-icon>
    </button>

    <span class="mr-16">{{ pagina }} de {{ totalPagina }}</span>

    <button mat-raised-button [disabled]="pagina === totalPagina" class="mat-accent mr-16" (click)="nextPagina()">
        <mat-icon class="blank">chevron_right</mat-icon>
    </button>

    <button mat-raised-button class="grey mr-16" (click)="zoomOut()">
        <mat-icon class="blank">zoom_out</mat-icon>
    </button>

    <button mat-raised-button class="grey mr-16" (click)="zoomIn()">
        <mat-icon class="blank">zoom_in</mat-icon>
    </button>

    <button mat-stroked-button class="blue mr-16" (click)="download()">
        <mat-icon class="blank">cloud_download</mat-icon> Fazer download
    </button>

</div>

<div class="pdf-container text-center">
    <pdf-viewer [src]="'data:application/pdf;base64,' + pdf"
                [page]="pagina"
                [zoom]="zoom"
                [show-all]="false"
                (after-load-complete)="afterLoadComplete($event)">
    </pdf-viewer>
</div>
