import {NgModule} from '@angular/core';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatButtonModule} from '@angular/material/button';
import {MatSortModule} from '@angular/material/sort';
import {MatRippleModule} from '@angular/material/core';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {FuseConfirmDialogModule, FuseWidgetModule} from '../../@fuse/components';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {HasAnyAuthorityDirective} from './has-any-authority.directive';
import {IsCurrentUserchibarrogreiDirective} from './is-current-user-chibarrogrei.directive';
import {MaterialFileInputModule} from 'ngx-material-file-input';
import {MatDividerModule} from '@angular/material/divider';
import {NgxMaskModule} from 'ngx-mask';
import {MatDialogModule} from '@angular/material/dialog';
import {DxLookupModule} from 'devextreme-angular';
import {MatAutocompleteModule} from '@angular/material/autocomplete';

@NgModule({
    imports: [
        FuseSharedModule,
        MatIconModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatTooltipModule,
        MatCardModule,
        MatInputModule,
        MatChipsModule,
        MatExpansionModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTabsModule,
        MatMenuModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatFormFieldModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseConfirmDialogModule,
        MatCheckboxModule,
        MaterialFileInputModule,
        MatDividerModule,
        NgxMaskModule,
        MatDialogModule,
        DxLookupModule,
        MatAutocompleteModule
    ],
    exports: [
        FuseSharedModule,
        MatIconModule,
        MatTableModule,
        MatPaginatorModule,
        MatButtonModule,
        MatTooltipModule,
        MatCardModule,
        MatInputModule,
        MatChipsModule,
        MatExpansionModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTabsModule,
        MatMenuModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatFormFieldModule,
        FuseSharedModule,
        FuseWidgetModule,
        FuseConfirmDialogModule,
        MatCheckboxModule,
        HasAnyAuthorityDirective,
        IsCurrentUserchibarrogreiDirective,
        MaterialFileInputModule,
        MatDividerModule,
        NgxMaskModule,
        MatDialogModule,
        DxLookupModule,
        MatAutocompleteModule
    ],
    declarations: [
        HasAnyAuthorityDirective, IsCurrentUserchibarrogreiDirective
    ]
})
export class SharedModule {
}
