import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { createRequestOption } from '../../util/request-util';
import { User } from '../model/user.model';
import { FilterSearch } from '../model/filtro.model';
import {CategorieModel} from "../model/categorie.model";

type EntityResponseType = HttpResponse<User>;
type BodyResponseType = HttpResponse<any>;
type EntityArrayResponseType = HttpResponse<User[]>;

@Injectable({ providedIn: 'root' })
export class UserService {
    public resourceUrl = environment.url + 'api/v1/users';

    constructor(protected http: HttpClient) {
    }

    create(user: User): Observable<EntityResponseType> {
        return this.http.post<User>(this.resourceUrl, user, { observe: 'response' });
    }

    update(user: User): Observable<EntityResponseType> {
        return this.http.put<User>(this.resourceUrl, user, { observe: 'response' });
    }

    inactivate(id: number): Observable<EntityResponseType> {
        return this.http.get<User>(this.resourceUrl + '/inactivate/' + id, { observe: 'response' });
    }

    activate(id: number): Observable<EntityResponseType> {
        return this.http.get<User>(this.resourceUrl + '/activate/' + id, { observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<User>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(request?: any, filters: Array<FilterSearch> = []): Observable<EntityArrayResponseType> {
        let options = createRequestOption(request);
        filters.forEach(value => options = options.append(value.key, value.value));
        return this.http.get<User[]>(`${this.resourceUrl}/search`, { params: options, observe: 'response' });
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }
    recoveryPassword(id: number): Observable<HttpResponse<any>> {
        return this.http.get<any>(`${this.resourceUrl}/recovery_password/${id}`, { observe: 'response' });
    }

    findAll(): Observable<EntityArrayResponseType> {
        return this.http.get<User[]>(`${this.resourceUrl}`, {observe: 'response'});
    }
}
