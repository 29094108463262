import {CategorieModel} from "./categorie.model";
import {User} from "./user.model";
import {ContactModel} from "./contact.model";
import {AddressModel} from "./address.model";
import {SocialNetworkModel} from "./social-network.model";

export enum StatusAnnouncement {
    LIBERADO = 'LIBERADO',
    REJEITADO = 'REJEITADO',
    EM_ANALISE = 'EM_ANALISE',
    CARROSSEL = 'CARROSSEL',
    PREMIUM = 'PREMIUM',
    CRIADO_APP = 'CRIADO_APP',
}

export class AnnouncementModel {
    public id?: number;
    public price?: number;
    public title?: string;
    public description?: string;
    public image?: string;
    public contentType?: string;
    public nomeArquivo?: string;
    public status?: StatusAnnouncement;
    public categories?: Array<CategorieModel>;
    public contacts?: Array<ContactModel>;
    public socialNetworks?: Array<SocialNetworkModel>;
    public user?: User;
    public createdAt?: Date;
    public address?: AddressModel;
}
