import {NgModule} from '@angular/core';
import {AuthenticationService} from './authentication.service';

@NgModule({
    providers: [
        AuthenticationService
    ],
    declarations: [],
    exports: [],
})
export class AuthModule {
}
