import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {PdfComponent} from './pdf.component';
import {PdfService} from './pdf.service';
import {DataUtils} from './data-util.service';
import {PdfViewerModule} from 'ng2-pdf-viewer';
// @ts-ignore
import {SharedModule} from '../../util/shared.module';

@NgModule({
    imports: [PdfViewerModule, SharedModule],
    declarations: [PdfComponent],
    entryComponents: [PdfComponent],
    exports: [PdfComponent, PdfViewerModule],
    providers: [PdfService, DataUtils],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class PdfModule {
}
