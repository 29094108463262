import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthenticationService {

    constructor(private http: HttpClient, private _router: Router) {
    }

    login(username: string, password: string): any {
        const login = { username: username, password: password };
        return this.http.post<any>(environment.url + 'api/v1/authenticate', login).toPromise()
            .then(result => {
                if (result) {
                    localStorage.setItem('token', result.token);
                }
            });
    }

    logout(): void {
        localStorage.clear();
        this._router.navigate(['/login']);
    }

    getAuthorities(): any {
        if (this.isAutenticate()) {
            return this.getTokenDecode().auth;
        }
        return [];
    }

    getNameCurrentUser(): any {
        if (this.isAutenticate()) {
            return this.getTokenDecode().name;
        }
        return '';
    }

    getIdCurrentUser(): any {
        if (this.isAutenticate()) {
            return this.getTokenDecode().id;
        }
        return '';
    }

    getEmailCurrentUser(): any {
        if (this.isAutenticate()) {
            return this.getTokenDecode().email;
        }
        return '';
    }

    private getTokenDecode(): any {
        return jwt_decode(localStorage.getItem('token'));
    }

    getToken(): any {
        return localStorage.getItem('token');
    }

    hasAnyAuthority(authorities: string[]): boolean {
        if (this.isAutenticate()) {
            const roles = this.getAuthorities();
            if (roles.length === 0) {
                return false;
            }

            for (let i = 0; i < authorities.length; i++) {
                if (roles.includes(authorities[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    isCurrentUserchibarrogrei() {
        return this.hasAnyAuthority(['chibarrogrei', 'FRANCHISEE_SUCCESS', 'OPERATIONAL',
            'FINANCIAL', 'ADMINISTRATIVE', 'REVIEWER', 'PROMOTER'])
    }

    isCurrentUserAdmFinancial() {
        return this.hasAnyAuthority(['ADM_FINANCIAL'])
    }

    isAutenticate(): boolean {
        return localStorage.getItem('token') != null;
    }

}
