import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {AddressModel} from '../main/model/address.model';
import {Observable} from 'rxjs';

@Injectable()
export class ZipCodeService {
    constructor(private http: HttpClient) {
    }

    get(cep: string): Observable<any> {
        return this.http.get(`//viacep.com.br/ws/${cep}/json`);
    }
}
