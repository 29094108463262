<div id="user" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <div class="center">

        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
             fxLayoutAlign.gt-sm="space-between center">

            <div class="logo mb-24 mb-md-0" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    account_box
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Usuários
                </span>
            </div>

            <div class="search-wrapper mr-20 ml-20">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matTooltip="Pesquisa avançada" (click)="openFilter()">filter_list_sharp</mat-icon>
                    <input placeholder="Pesquisar..." [(ngModel)]="searchText" (keydown.enter)="search()">
                    <mat-icon *ngIf="searchText.length > 0" (click)="clear()">clear</mat-icon>
                </div>
            </div>

            <div fxLayoutAlign="end center">
                <button mat-raised-button class="fuse-white mt-24 mt-md-0" (click)="search()">
                    <mat-icon>search</mat-icon>
                    <span>Pesquisar</span>
                </button>
            </div>
        </div>

        <div class="content-card mat-elevation-z8">

            <mat-table class="user-table" [dataSource]="dataSource" [@animateStagger]="{value:'50'}" matSort
                       fusePerfectScrollbar>

                <mat-header-row *matHeaderRowDef="columns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let user; columns: columns;" matRipple></mat-row>

                <ng-container matColumnDef="id" fxFlex="100">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Código</mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p class="text-truncate">{{user.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="name" [fxFlex]="100">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Nome</mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p matTooltip="{{user.name}}" class="text-truncate">{{user.name}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="email">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p matTooltip="{{user.email}}" class="text-truncate">{{user.email}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Telefone</mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p matTooltip="{{user.phone}}" class="text-truncate">{{user.phone | mask: '(00) 00000-0000'}}
                        </p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="cpf">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>CPF</mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <p matTooltip="{{user.cpf}}" class="text-truncate">{{user.cpf | mask: '000.000.000-00'}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="buttons">

                    <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">
                        <span>Opções</span>
                    </mat-header-cell>

                    <mat-cell *matCellDef="let user">
                        <div fxFlex="row" fxLayoutAlign="center center">
                            <button mat-mini-fab class="green icon" matTooltip="Ativo"
                                    *ngIf="user.activated == 'ACTIVE'" (click)="inactivate(user.id)">
                                <mat-icon [inline]="true" style="color: white">thumb_up</mat-icon>
                            </button>
                            <button mat-mini-fab class="red icon" matTooltip="Inativo"
                                    *ngIf="user.activated == 'INACTIVE'" (click)="activate(user.id)">
                                <mat-icon [inline]="true" style="color: white">thumb_down</mat-icon>
                            </button>
                            <button mat-mini-fab class="red icon" matTooltip="Excluir" (click)="delete(user.id)">
                                <mat-icon [inline]="true" style="color: white">delete</mat-icon>
                            </button>
                            <button mat-mini-fab class="grey icon" matTooltip="Nova Senha" (click)="resetPassword(user.id)">
                                <mat-icon [inline]="true" style="color: white">lock</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>
            </mat-table>

            <mat-paginator fxLayoutAlign="center center" [length]="lengthItems" [pageSize]="10"
                           [showFirstLastButtons]="true" [pageSizeOptions]="[10, 25, 50, 75, 100]">
            </mat-paginator>

        </div>
    </div>
</div>
