<div>
    <div class="logo mb-20" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="90">
            <span class="logo-text h1">Salvar Rede Social</span>
        </div>
        <div fxFlex="10">
            <button mat-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="card-divider mb-0"></div>

    <div fxLayout="column">
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="saveForm" [formGroup]="saveForm">
            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="50">
                <mat-label>Rede Social</mat-label>
                <mat-select [(ngModel)]="typeSocialNetwork" placeholder="Rede Social"
                            [ngModelOptions]="{standalone: true}">
                    <mat-option [value]="'SITE'">Site</mat-option>
                    <mat-option [value]="'INSTAGRAM'">Instagram</mat-option>
                    <mat-option [value]="'FACEBOOK'">Facebook</mat-option>
                    <mat-option [value]="'YOUTUBE'">Youtube</mat-option>
                    <mat-option [value]="'TWITTER'">Twitter</mat-option>
                    <mat-option [value]="'LINKEDIN'">Linkedin</mat-option>
                    <mat-option [value]="'TIKTOK'">Tiktok</mat-option>
                    <mat-option [value]="'OTHERS'">Outros</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="50">
                <mat-label>Link da rede social</mat-label>
                <input matInput placeholder="Url" required name="url" formControlName="url"
                       [(ngModel)]="url">
                <mat-icon matSuffix style="color: gray;">url</mat-icon>
                <mat-error>
                    Campo obrigatório.
                </mat-error>
            </mat-form-field>


            <button mat-button class="button-social" [disabled]="saveForm.invalid" (click)="salvar()">
                Salvar
            </button>
        </form>
    </div>
</div>
