export class State {
    public acronym?: string;
    public name?: string;

    constructor(acronym?: string, name?: string) {
        this.name = name;
        this.acronym = acronym;
    }

    static getStates(): Array<State> {
        const states = new Array<State>();
        states.push(new State('AC', 'Acre'));
        states.push(new State('AL', 'Alagoas'));
        states.push(new State('AP', 'Amapá'));
        states.push(new State('AM', 'Amazonas'));
        states.push(new State('BA', 'Bahia'));
        states.push(new State('CE', 'Ceará'));
        states.push(new State('ES', 'Espírito Santo'));
        states.push(new State('GO', 'Goiás'));
        states.push(new State('MA', 'Maranhão'));
        states.push(new State('MT', 'Mato Grosso'));
        states.push(new State('MS', 'Mato Grosso do Sul'));
        states.push(new State('MG', 'Minas Gerais'));
        states.push(new State('PA', 'Pará'));
        states.push(new State('PB', 'Paraíba'));
        states.push(new State('PR', 'Paraná'));
        states.push(new State('PE', 'Pernambuco'));
        states.push(new State('PI', 'Piauí'));
        states.push(new State('RJ', 'Rio de Janeiro'));
        states.push(new State('RN', 'Rio Grande do Norte'));
        states.push(new State('RS', 'Rio Grande do Sul'));
        states.push(new State('RO', 'Rondônia'));
        states.push(new State('RR', 'Roraima'));
        states.push(new State('SC', 'Santa Catarina'));
        states.push(new State('SP', 'São Paulo'));
        states.push(new State('SE', 'Sergipe'));
        states.push(new State('TO', 'Tocantins'));
        states.push(new State('DF', 'Distrito Federal'));
        return states;
    }
}
