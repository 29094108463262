import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Location} from '@angular/common';
import {Subject} from 'rxjs';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute} from '@angular/router';
import {UtilMessage} from '../../../util/util-message';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CategorieModel} from '../../model/categorie.model';
import {FileInput, FileInputComponent, FileValidator} from 'ngx-material-file-input';
import {CategorieService} from '../categorie.service';
import {FileModel, MimeHelper} from "../../model/file.model";

@Component({
    selector: 'edit-categorie',
    templateUrl: './categorie-save.component.html',
    styleUrls: ['./categorie-save.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class CategorieSaveComponent implements OnInit, OnDestroy {

    categorie: CategorieModel;
    isUpdate = true;
    saveForm: FormGroup;
    imageFileInput: FileInput;
    image: string;
    isClearImage = false;
    nomeArquivo: string;
    imageContentType: string;
    private _unsubscribeAll: Subject<any>;
    private maxSize = 2;

    constructor(private _location: Location,
                private _route: ActivatedRoute,
                private _message: UtilMessage,
                private _formBuilder: FormBuilder,
                private _productCategorieService: CategorieService) {
        this.categorie = new CategorieModel();
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this._route.params.subscribe(params => {
            const id = params['id'];
            this.isUpdate = id !== 'new';
            this.loadProductCategirie(id);
        });
        this.saveForm = this.createForm();
    }

    private createForm(): FormGroup {
        return this._formBuilder.group({
            code: [''],
            description: [''],
            nomeArquivo: [''],
            imageContentType: [null],
            image: ['', [FileValidator.maxContentSize(this.maxSize * 1048576)]],
        });
    }

    private loadProductCategirie(id): void {
        if (this.isUpdate) {
            this._productCategorieService.find(id).toPromise().then(result => {
                this.categorie = result.body;
                this.image = this.categorie.image;
                this.imageContentType = this.categorie.contentType;
                this.nomeArquivo = this.categorie.nomeArquivo;
                this.saveForm.patchValue(this.categorie);
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    save(categorie: CategorieModel): void {
        categorie.image = this.image;
        categorie.nomeArquivo = this.nomeArquivo;
        categorie.contentType = this.imageContentType;
        this._productCategorieService.create(categorie).toPromise()
            .then((value) => {
                this._message.success('Registro salvo com sucesso!');
                this.close();
            }).catch((error) => this._message.error(error));
    }

    update(categorie: CategorieModel): void {
        categorie.image = this.image;
        categorie.nomeArquivo = this.nomeArquivo;
        categorie.contentType = this.imageContentType;
        this._productCategorieService.update(categorie).toPromise()
            .then(() => {
                this._message.success('Registro atualizado com sucesso!');
                this.close();
            }).catch((error) => this._message.error(error));
    }

    close(): void {
        this._location.back();
    }

    uploadDocument(value): void {
        const file = value.target.files[0];
        const ext = FileModel.getFileExtension(file.name);
        const m = MimeHelper.findByExtension(ext);

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
            this.imageContentType = m.documentoContentType;
            this.nomeArquivo = file.name;
            this.image = btoa(reader.result.toString());
            this.saveForm.patchValue({
                'nomeArquivo': file.name,
                'image': this.image,
                'imageContentType': m.documentoContentType
            });
        };
    }

    clearFile() {
        this.isClearImage = true;
        this.image = null;
        this.nomeArquivo = null;
        this.imageContentType = null;
        this.saveForm.patchValue({
            'nomeArquivo': null,
            'image': null,
            'imageContentType': null
        });
    }

    getImageUrl(id: number): string {
        return `https://chibarro-grei-prod-public-assets.s3.amazonaws.com/categories/${id}.png?v=${new Date().getTime()}`;  // Adiciona um timestamp
    }
}
