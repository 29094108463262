import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../main/auth/authentication.service';

@Directive({
  selector: '[isCurrentUserchibarrogrei]'
})
export class IsCurrentUserchibarrogreiDirective {

    private currentUserchibarrogrei: boolean;

    constructor(private _authentication: AuthenticationService,
                private templateRef: TemplateRef<any>,
                private viewContainerRef: ViewContainerRef) {}

    @Input()
    set isCurrentUserchibarrogrei(value: boolean) {
        this.currentUserchibarrogrei = value;
        this.updateView();
    }

    private updateView(): void {
        const hasAnyAuthority = this._authentication.isCurrentUserchibarrogrei();
        this.viewContainerRef.clear();
        if (hasAnyAuthority) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

}

