import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Location} from '@angular/common';
import {Subject} from 'rxjs';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute} from '@angular/router';
import {UtilMessage} from '../../../util/util-message';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ShopModel} from '../../model/shop.model';
import {FileInput, FileInputComponent, FileValidator} from 'ngx-material-file-input';
import {ShopService} from '../shop.service';
import {CategorieModel} from "../../model/categorie.model";
import {CategorieService} from "../../categorie/categorie.service";
import {User} from "../../model/user.model";
import {UserService} from "../../user/user.service";
import {ContactModel} from "../../model/contact.model";
import {State} from "../../model/state.model";
import {ZipCodeService} from "../../../util/zip-code.service";
import {MatDialog} from "@angular/material/dialog";
import {AddressModel} from "../../model/address.model";
import {SocialNetworkModel, TypeSocialNetwork} from "../../model/social-network.model";
import {ContactService} from "../../announcement/contact/contact-dialog.service";
import {ContactDialog} from "../../announcement/contact/contact-dialog.component";
import {FileModel, MimeHelper} from "../../model/file.model";

@Component({
    selector: 'edit-shop',
    templateUrl: './shop-save.component.html',
    styleUrls: ['./shop-save.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ShopSaveComponent implements OnInit, OnDestroy, ContactService {

    shop: ShopModel;
    states: Array<State>;
    contacts: Array<ContactModel> = [];
    users: Array<User> = [];
    isUpdate = true;
    isClearImage = false;
    saveForm: FormGroup;
    image: string;
    meetingDay: string;
    meetingTime: string;
    imageContentType: string;
    nomeArquivo: string;
    private _unsubscribeAll: Subject<any>;
    maxSize = 2;
    filteredUsers: Array<User> = [];
    filteredCategories: Array<CategorieModel> = [];

    constructor(private _location: Location,
                private _route: ActivatedRoute,
                private dialog: MatDialog,
                private _message: UtilMessage,
                private _formBuilder: FormBuilder,
                private _zipCodeService: ZipCodeService,
                private _userService: UserService,
                private _shopService: ShopService) {
        this.shop = new ShopModel();
        this.shop.address = new AddressModel();
        this._unsubscribeAll = new Subject();
        this._userService.findAll().toPromise().then(value => {
            this.users = value.body;
            this.filteredUsers = this.users;
        });
        this.states = State.getStates();
    }

    ngOnInit(): void {
        this._route.params.subscribe(params => {
            const id = params['id'];
            this.isUpdate = id !== 'new';
            this.loadShop(id);
        });
        this.saveForm = this.createForm();
    }

    onKeyUp(zipCode): void {
        if (zipCode !== null && zipCode.target.value.length === 9) {
            this._zipCodeService.get(this.shop.address.zipCode).toPromise().then(response => {
                this.shop.address.street = response.logradouro;
                this.shop.address.district = response.bairro;
                this.shop.address.city = response.localidade;
                this.shop.address.state = response.uf;
            });
        }
    }

    private createForm(): FormGroup {
        return this._formBuilder.group({
            code: [''],
            name: [''],
            fundation: [''],
            contacts: [''],
            status: [''],
            rito: [''],
            power: [''],
            user: [''],
            image: ['', [FileValidator.maxContentSize(this.maxSize * 1048576)]],
            description: [''],
            district: [''],
            zipCode: [''],
            street: [''],
            number: [''],
            state: [''],
            nomeArquivo: [''],
            contentType: [null],
            city: [''],
            complement: [''],
        });
    }

    private loadShop(id): void {
        if (this.isUpdate) {
            this._shopService.find(id).toPromise().then(result => {
                this.shop = result.body;
                this.contacts = this.shop.contacts;
                this.image = this.shop.image;
                this.imageContentType = this.shop.contentType;
                this.nomeArquivo = this.shop.nomeArquivo;
                let description = this.shop.description.split(" às ");
                this.meetingDay = description[0];
                this.meetingTime = description[1];
                this.saveForm.patchValue(this.shop);
            });
        } else {
            this.shop.name = 'ARLS ';
            this.shop.rito = 'Escocês Antigo e Aceito';
            this.meetingTime = '20:00'
            this.meetingDay = 'Sexta-feira'
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    save(shop: ShopModel): void {
        shop.image = this.image;
        shop.nomeArquivo = this.nomeArquivo;
        shop.contentType = this.imageContentType;
        shop.description = this.meetingDay.concat(" às ").concat(this.meetingTime)
        this._shopService.create(shop).toPromise()
            .then((value) => {
                this._message.success('Registro salvo com sucesso!');
                this.close();
            }).catch((error) => this._message.error(error));
    }

    update(shop: ShopModel): void {
        shop.image = this.image;
        shop.nomeArquivo = this.nomeArquivo;
        shop.contentType = this.imageContentType;
        shop.description = this.meetingDay.concat(" às ").concat(this.meetingTime)
        this._shopService.update(shop).toPromise()
            .then(() => {
                this._message.success('Registro atualizado com sucesso!');
                this.close();
            }).catch((error) => this._message.error(error));
    }

    close(): void {
        this._location.back();
    }

    uploadDocument(value): void {
        const file = value.target.files[0];
        const ext = FileModel.getFileExtension(file.name);
        const m = MimeHelper.findByExtension(ext);

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
            this.imageContentType = m.documentoContentType;
            this.nomeArquivo = file.name;
            this.image = btoa(reader.result.toString());
            this.saveForm.patchValue({
                'nomeArquivo': file.name,
                'image': this.image,
                'imageContentType': m.documentoContentType
            });
        };
    }

    clearFile() {
        this.isClearImage = true;
        this.image = null;
        this.nomeArquivo = null;
        this.imageContentType = null;
        this.saveForm.patchValue({
            'nomeArquivo': null,
            'image': null,
            'imageContentType': null
        });
    }

    compareID(one: any, two: any): boolean {
        return one && two ? one.id === two.id : one === two;
    }

    addContact(contact: ContactModel) {
        this.contacts.push(contact);
    }
    openContact(): void {
        const dialogRef = this.dialog.open(ContactDialog, {
            width: '30%',
            disableClose: true
        });
        dialogRef.componentInstance.contactService = this;
    }

    filterUser(value: string): void {
        this.filteredUsers = this.users.filter((user) =>
            user.name.toLowerCase().includes(value.toLowerCase()) || user.id == Number(value)
        );
    }

    getImageUrl(id: number): string {
        return `https://chibarro-grei-prod-public-assets.s3.amazonaws.com/shops/${id}.png?v=${new Date().getTime()}`;  // Adiciona um timestamp
    }
}
