export class UtilFunctions {

  private static soma(soma: number, cpf: string, digito: number, length: number) {
    for (let index = 1; index <= length; index++) {
      soma = soma + parseInt(cpf.substring(index - 1, index), 10) * (digito - index);
    }
    return soma;
  }

  public static removeNonAlphaNumeric(value: string): string {
    if (!UtilFunctions.isValidStringOrArray(value)) {
      return '';
    }
    const regExp = /[^A-Za-z0-9]/g;
    return value.replace(regExp, '');
  }

  public static removeNonNumbers(value: string): string {
    if (!UtilFunctions.isValidStringOrArray(value)) {
      return '';
    }
    const regExp = /[^0-9]/g;
    return value.replace(regExp, '');
  }

  public static removeAccents(value: string): string {
    if (!UtilFunctions.isValidStringOrArray(value)) {
      return '';
    }
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public static formatDateDatabase(d: Date, includeTime: boolean) {
    const year = '' + d.getFullYear();
    let month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      hour = '' + d.getHours(),
      minute = '' + d.getMinutes(),
      seconds = '' + d.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }

    let formatedDate: string = year + '-' + month + '-' + day;
    if (includeTime) {
      formatedDate += ' ' + hour + ':' + minute + ':' + seconds;
    }
    return formatedDate;
  }

  public static formatDateBrazil(date: Date, includeTime: boolean) {
    if (!UtilFunctions.isValidObject(date)) {
      return undefined;
    }
    const year = '' + date.getFullYear();
    let month = '' + (date.getMonth() + 1),
      day = '' + date.getDate(),
      hour = '' + date.getHours(),
      minute = '' + date.getMinutes(),
      seconds = '' + date.getSeconds();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    if (hour.length < 2) {
      hour = '0' + hour;
    }
    if (minute.length < 2) {
      minute = '0' + minute;
    }
    if (seconds.length < 2) {
      seconds = '0' + seconds;
    }

    let formatedDate: string = day + '/' + month + '/' + year;
    if (includeTime) {
      formatedDate += ' ' + hour + ':' + minute + ':' + seconds;
    }
    return formatedDate;
  }

  public static parseDateDataBaseFormat(dateString: string): Date {
    const searchValue = /-/g;
    return new Date(dateString.replace(searchValue, '/'));
  }

  public static roundNumber(value: number): number {
    if (isNaN(value)) {
      return null;
    }
    value = Math.round(value * 100) / 100;
    return value;
  }

  public static isValidStringOrArray(value: string | any[]): boolean {
    return value !== null && value !== undefined && value.length > 0;

  }

  public static isValidObject(value: any): boolean {
    return !!(value !== null && value !== undefined && value);

  }

  public static arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  public static convertBase64ToBlobData(base64Data: string, contentType: string, sliceSize = 512) {
    base64Data = base64Data.replace(/\s/g, '');
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  public static getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  public static formatarDocumento(documento: string) {
      if (!UtilFunctions.isValidStringOrArray(documento)) {
          return '';
      }
      documento = UtilFunctions.removeNonNumbers(documento);
      if (documento.length === 11) {
          return documento.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,'\$1.\$2.\$3\-\$4');
      } else if (documento.length === 14) {
          return documento.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,'\$1.\$2.\$3\/\$4\-\$5');
      }
      return '';
  }

  public static getNomeMes(mes: number) {
      const date = new Date();
      date.setMonth(mes);
      const month = date.toLocaleString('default', { month: 'long' });
      return month;
  }

  public static toTitleCase(str): string {
      str = str.toLowerCase().split(' ');
      for (let i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(' ');
  }

  public static getEnumKeyByEnumValue(myEnum, enumValue) {
      for (const key of Object.keys(myEnum)) {
        const value: string = myEnum[key];
        if (value === enumValue.toString()) {
          return key;
        }
      }
      return null;
  }

  public static getHttpErrorMessage(value) {
    let errorMessage;
    if (value.error.detail !== undefined) {
      errorMessage = value.error.detail;
    } else if (value.error && value.error.fieldErrors) {
      errorMessage = value.error.fieldErrors[0].message;
    } else if (value.error && value.error.message) {
      errorMessage = value.error.message;
    } else if (value.message !== undefined) {
      errorMessage = value.message;
    } else {
      errorMessage = value;
    }
    if (errorMessage === null || errorMessage === undefined) {
      errorMessage = 'Erro desconhecido';
    }
    return errorMessage;
  }
}
