<div>
    <div class="logo mb-20" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="90">
            <span class="logo-text h1">Salvar Contato</span>
        </div>
        <div fxFlex="10">
            <button mat-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="card-divider mb-0"></div>

    <div fxLayout="column">
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" name="saveForm" [formGroup]="saveForm">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Nome</mat-label>
                <input matInput placeholder="Nome" required name="name" formControlName="name" [(ngModel)]="name">
                <mat-icon matSuffix style="color: gray;">account_circle</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Telefone</mat-label>
                <input matInput placeholder="Telefone" required name="phone" formControlName="phone"
                       [(ngModel)]="phone">
                <mat-icon matSuffix style="color: gray;">phone</mat-icon>
                <mat-error>
                    Campo obrigatório.
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Pronome de Tratamento</mat-label>
                <input matInput placeholder="Pronome de Tratamento" required name="pronounTreatment"
                       formControlName="pronounTreatment" [(ngModel)]="pronounTreatment">
                <mat-icon matSuffix style="color: gray;">fact_check</mat-icon>
                <mat-error>
                    Campo obrigatório.
                </mat-error>
            </mat-form-field>

            <button mat-button class="button-contact" [disabled]="saveForm.invalid" (click)="salvar()">
                Salvar
            </button>
        </form>
    </div>
</div>
