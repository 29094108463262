<div id="home" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <div class="center">

        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
             fxLayoutAlign.gt-sm="space-between center">

            <div class="logo mb-24 mb-md-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    home
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Olá,  {{userName}}!
                </span>
            </div>
        </div>
    </div>
</div>
