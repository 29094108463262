import { environment } from '../../environments/environment';
import { AuthenticationService } from '../main/auth/authentication.service';

export class HotjarUtil {

    public static config(document: any, authenticationService: AuthenticationService): void {
       // Config hotjar
       if(!authenticationService.isAutenticate()) return;
       if(authenticationService.isCurrentUserchibarrogrei()) return;
   
       if(environment.enableHotjarTracking && authenticationService.hasAnyAuthority(['FRANCHISEE'])) {
           ((h, o, t, j, a, r) => {
             h.hj =
               h.hj ||
               /* tslint:disable:only-arrow-functions */
               function () {
                 (h.hj.q = h.hj.q || []).push(arguments);
               };
             h._hjSettings = { hjid: 3018016, hjsv: 6 };
             a = o.getElementsByTagName('head')[0];
             r = o.createElement('script');
             r.async = 1;
             r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
             a.appendChild(r);
           })(window as any, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
       }
     }
   
}

