import {NgModule} from '@angular/core';
import {FuseSharedModule} from '../../@fuse/shared.module';
import {LoginModule} from './login/login.module';
import {RouterModule} from '@angular/router';
import {UserModule} from './user/user.module';
import {AuthModule} from './auth/auth.module';
import {CategorieModule} from './categorie/categorie.module';
import {PdfModule} from './pdf/pdf.module';
import {HomeModule} from './home/home.module';
import {EventModule} from "./event/event.module";
import {AnnouncementModule} from "./announcement/announcement.module";
import {ShopModule} from "./shop/shop.module";
import {DashboardModule} from "./dashboard/dashboard.module";

const routes = [
    {
        path: 'home',
        loadChildren: './home/home.module#HomeModule'
    },
    {
        path: 'user',
        loadChildren: './user/user.module#UserModule'
    },
    {
        path: 'event',
        loadChildren: './event/event.module#EventModule'
    },
    {
        path: 'announcement',
        loadChildren: './announcement/announcement.module#AnnouncementModule'
    },
    {
        path: 'categorie',
        loadChildren: './categorie/product-categorie.module#ForgotPasswordModule'
    },
    {
        path: 'shop',
        loadChildren: './shop/shop.module#ShopModule'
    },
    {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule'
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        FuseSharedModule,
        UserModule,
        LoginModule,
        AuthModule,
        CategorieModule,
        AnnouncementModule,
        ShopModule,
        EventModule,
        HomeModule,
        PdfModule,
        DashboardModule
    ],
})
export class MainModule {
}
