import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {AnnouncementSaveComponent} from "../save/announcement-save.component";
import {ContactModel} from "../../model/contact.model";
import {FormBuilder, FormGroup} from "@angular/forms";
import {FileValidator} from "ngx-material-file-input";
import {Command} from "@angular/cli/models/command";
import {ContactService} from "./contact-dialog.service";

@Component({
    selector: 'save-contact-dialog',
    templateUrl: './contact-dialog.component.html',
    styleUrls: ['./contact-dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class ContactDialog {

    name: string;
    phone: string;
    pronounTreatment: string;
    contactService: ContactService;
    saveForm: FormGroup;

    constructor(public _dialogRef: MatDialogRef<ContactDialog>, private _formBuilder: FormBuilder) {
        this.saveForm = this.createForm();
    }

    private createForm(): FormGroup {
        return this._formBuilder.group({
            name: [''],
            pronounTreatment: [''],
            phone: [''],
        });
    }

    salvar(): void {
        let contact = new ContactModel();
        contact.name = this.name;
        contact.phone = this.phone;
        contact.pronounTreatment = this.pronounTreatment;
        this.contactService.addContact(contact);
        this.close();
    }

    close(): void {
        this._dialogRef.close();
    }
}
