import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';

import {fuseAnimations} from '@fuse/animations';
import {UserService} from './user.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import {merge, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Status, User} from '../model/user.model';
import {FilterSearch} from '../model/filtro.model';
import {FilterUserDialog} from './filter/filter-user-dialog';
import {MatDialog} from '@angular/material/dialog';
import {ExecutorFilter} from '../../util/executor-filter';
import { AuthenticationService } from '../auth/authentication.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class UserComponent implements AfterViewInit, OnDestroy, ExecutorFilter {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('filter') filter: ElementRef;

    filters = new Array<FilterSearch>();
    dataSource = new Array<User>();
    searchText = '';
    lengthItems = 0;
    columns = ['id', 'name', 'email', 'phone', 'cpf', 'buttons'];

    private _unsubscribeAll: Subject<any>;

    constructor(private _service: UserService, private dialog: MatDialog) {
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit(): void {
        this.paginator._intl.itemsPerPageLabel = 'Itens por página:';
        this.paginator.pageIndex = 0;
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.loadUsers();

        merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadUsers())).subscribe();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    loadUsers(): void {
        const request = {
            page: this.paginator.pageIndex,
            size: this.paginator.pageSize,
            sort: this.sort.active ? this.sort.active + ',' + this.sort.direction : 'id,asc'
        };
        this._service.query(request, this.filters).subscribe(
            (res: HttpResponse<User[]>) => {
                this.lengthItems = parseInt(res.headers.get('X-Total-Count'), 10);
                this.dataSource = res.body;
            },
            (res: HttpErrorResponse) => Swal.fire('Erro...', res.message, 'error')
        );
    }

    activate(id: number): void {
        this._service.activate(id).toPromise()
            .then(() => this.loadUsers())
            .catch(error => Swal.fire('Erro...', error, 'error'));
    }

    inactivate(id: number): void {
        this._service.inactivate(id).toPromise()
            .then(() => this.loadUsers())
            .catch(error => Swal.fire('Erro...', error, 'error'));
    }

    search(): void {
        this.paginator.pageIndex = 0;
        this.filters = new Array<FilterSearch>();
        this.filters.push(new FilterSearch('search', this.searchText));
        this.loadUsers();
    }

    delete(id: number): void {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Você não poderá reverter isso!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Excluir'
        }).then((result) => {
            if (result.isConfirmed) {
                this._service.delete(id).toPromise()
                    .then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro removido com sucesso!',
                            showConfirmButton: false,
                            timer: 2000
                        });
                        this.loadUsers();
                    }).catch(error => Swal.fire('Erro...', 'Não foi possível remover o usuário!', 'error'));
            }
        });
    }

    resetPassword(id: number): void {
        Swal.fire({
            title: 'Nova senha',
            text: 'Deseja gerar uma nova senha?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Gerar'
        }).then((result) => {
            if (result.isConfirmed) {
                this._service.recoveryPassword(id).toPromise()
                    .then((value) => {
                        Swal.fire({
                            icon: 'success',
                            text : 'Foi enviado um e-mail para o usuário com a nova senha.',
                            title: 'Senha gerada com sucesso!',
                            showConfirmButton: false,
                            timer: 2000
                        });
                    }).catch(error => Swal.fire('Erro...', 'Não foi possível gerar nova senha do usuário!', 'error'));
            }
        });
    }

    clear(): void {
        this.searchText = '';
        this.filters = new Array<FilterSearch>();
        this.loadUsers();
    }

    openFilter(): void {
        const dialogRef = this.dialog.open(FilterUserDialog, {
            width: '30%',
            disableClose: true
        });
        dialogRef.componentInstance.executorFilter = this;
        dialogRef.componentInstance.currentFilters = this.filters;
    }

    executeFilter(filters: Array<FilterSearch>): void {
        this.paginator.pageIndex = 0;
        this.searchText = '';
        this.filters = filters
        this.loadUsers();
    }
}
