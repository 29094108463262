
export class AddressModel {
    zipCode: string;
    street?: string;
    number?: string;
    state?: string;
    city?: string;
    district?: string;
    complement?: string;
}
