import {NgModule} from '@angular/core';
import {SharedModule} from '../../util/shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {AnnouncementService} from './announcement.service';
import {AnnouncementComponent} from './announcement.component';
import {RouterModule} from '@angular/router';
import {AnnouncementSaveComponent} from "./save/announcement-save.component";
import {FilterAnnouncementDialog} from "./filter/filter-announcement-dialog.component";
import {ContactDialog} from "./contact/contact-dialog.component";
import {MatSelectFilterModule} from "mat-select-filter";
import {SocialNetworkDialog} from "./social-network/social-network-dialog.component";

const routes = [
    {
        path: 'announcement',
        component: AnnouncementComponent
    },
    {
        path     : 'announcement/:id',
        component: AnnouncementSaveComponent
    }
];

@NgModule({
    declarations: [
        AnnouncementComponent,
        AnnouncementSaveComponent,
        FilterAnnouncementDialog,
        ContactDialog,
        SocialNetworkDialog
    ],
    exports: [
        AnnouncementComponent,
        AnnouncementSaveComponent,
        FilterAnnouncementDialog,
        ContactDialog,
        SocialNetworkDialog
    ],
    entryComponents: [
        FilterAnnouncementDialog,
        ContactDialog,
        SocialNetworkDialog
    ],
    providers: [
        AnnouncementService
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgxMaskModule,
        MatSelectFilterModule
    ]
})
export class AnnouncementModule { }
