import {CategorieModel} from "./categorie.model";
import {User} from "./user.model";
import {ContactModel} from "./contact.model";
import {AddressModel} from "./address.model";
import {SocialNetworkModel} from "./social-network.model";

export enum StatusShop {
    LIBERADO = 'LIBERADO',
    REJEITADO = 'REJEITADO',
    EM_ANALISE = 'EM_ANALISE'
}

export class ShopModel {
    public id?: number;
    public name?: string;
    public power?: string;
    public rito?: string;
    public description?: string;
    public fundation?: Date;
    public status?: StatusShop;
    public image?: string;
    public contentType?: string;
    public nomeArquivo?: string;
    public contacts?: Array<ContactModel>;
    public user?: User;
    public address?: AddressModel;
}
