import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../main/auth/authentication.service';

@Directive({
  selector: '[isHasAnyAuthority]'
})
export class HasAnyAuthorityDirective {
    private authorities: string[];

    constructor(private _authentication: AuthenticationService,
                private templateRef: TemplateRef<any>,
                private viewContainerRef: ViewContainerRef) {}

    @Input()
    set isHasAnyAuthority(value: string | string[]) {
        this.authorities = typeof value === 'string' ? [value] : value;
        this.updateView();
    }

    private updateView(): void {
        const hasAnyAuthority = this._authentication.hasAnyAuthority(this.authorities);
        this.viewContainerRef.clear();
        if (hasAnyAuthority) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }
}

