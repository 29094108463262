import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {createRequestOption} from '../../util/request-util';
import {FilterSearch} from '../model/filtro.model';
import {AnnouncementModel} from "../model/announcement.model";

type EntityResponseType = HttpResponse<AnnouncementModel>;
type EntityArrayResponseType = HttpResponse<AnnouncementModel[]>;

@Injectable({providedIn: 'root'})
export class AnnouncementService {
    public resourceUrl = environment.url + 'api/v1/announcements';

    constructor(protected http: HttpClient) {
    }

    create(event: AnnouncementModel): Observable<EntityResponseType> {
        return this.http.post<AnnouncementModel>(`${this.resourceUrl}/web`, event, {observe: 'response'});
    }

    update(event: AnnouncementModel): Observable<EntityResponseType> {
        return this.http.put<AnnouncementModel>(this.resourceUrl, event, {observe: 'response'});
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<AnnouncementModel>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    query(request?: any, filters: Array<FilterSearch> = []): Observable<EntityArrayResponseType> {
        let options = createRequestOption(request);
        filters.forEach(value => options = options.append(value.key, value.value));
        return this.http.get<AnnouncementModel[]>(`${this.resourceUrl}/search/web`, {params: options, observe: 'response'});
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    deleteImage(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/image/${id}`, {observe: 'response'});
    }
}
