<div id="categorie" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <div class="center">

        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" (click)="close()" [routerLink]="'/view/categorie'">
                    <mat-icon style="color: white">arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">

                    <div fxLayout="row" fxLayoutAlign="start center" class="mb-12">
                        <mat-icon class="secondary-text s-24">home</mat-icon>
                        <mat-icon class="secondary-text s-24">chevron_right</mat-icon>
                        <div class="subtitle secondary-text">
                            <span>Categorias</span>
                        </div>
                        <mat-icon class="secondary-text s-24">chevron_right</mat-icon>
                        <div class="subtitle secondary-text">
                            <span>{{this.isUpdate ? 'Editar categoria' : 'Salvar categoria'}}</span>
                        </div>
                    </div>

                    <div class="h2" *ngIf="this.isUpdate">
                        # {{categorie.id}} - {{categorie.title}}
                    </div>

                    <div class="logo mb-24 mb-md-0" *ngIf="!this.isUpdate"
                         fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="logo-icon s-32 mr-16"
                                  [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            assignment
                        </mat-icon>
                        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            Preencha os dados
                        </span>
                    </div>

                </div>
            </div>

            <button mat-raised-button class="fuse-white mt-24 mt-md-0" (click)="update(categorie)"
                    *ngIf="this.isUpdate" [disabled]="saveForm.invalid">
                <span>ATUALIZAR</span>
            </button>

            <button mat-raised-button class="fuse-white mt-24 mt-md-0"
                    (click)="save(categorie)" *ngIf="!this.isUpdate" [disabled]="saveForm.invalid">
                <span>SALVAR</span>
            </button>
        </div>

        <div class="content-card">

            <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

                <form fxLayout="column" class="w-100-p" fxLayoutAlign="start" fxFlex="1 0 auto" name="saveForm"
                      [formGroup]="saveForm">

                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="100">
                                <mat-label>Descrição</mat-label>
                                <input matInput placeholder="Descrição"
                                       name="description"
                                       formControlName="description"
                                       [(ngModel)]="categorie.title"
                                       required>
                                <mat-icon matSuffix style="color: gray;">description</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="100">
                                <mat-label>Imagem</mat-label>
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <img [src]="'data:' + imageContentType + ';base64,' + image"
                                         *ngIf="this.image != null" class="avatar"/>
                                    <img [src]="getImageUrl(categorie.id)"
                                         *ngIf="categorie.id != undefined && !isClearImage" class="avatar"/>

                                    <button mat-icon-button matPrefix *ngIf="nomeArquivo == null"
                                            (click)="f_input.click()">
                                        <mat-icon>attach_file</mat-icon>
                                        Selecione...
                                    </button>

                                    <input type="text text-truncate" readonly matInput
                                           formControlName="nomeArquivo" name="nomeArquivo"/>

                                    <input
                                            type="file"
                                            hidden
                                            accept="image/png"
                                            #f_input
                                            (change)="uploadDocument($event)"
                                    />

                                    <button mat-icon-button matSuffix *ngIf="nomeArquivo != null"
                                            (click)="clearFile(); f_input.value=null">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </div>
                                <mat-error *ngIf="saveForm.get('image').hasError('maxContentSize')">
                                    O tamanho total não deve exceder {{maxSize}}MB
                                </mat-error>
                                <mat-error *ngIf="!saveForm.get('image').hasError('maxContentSize')">
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>

                    </div>

                </form>

            </div>

        </div>

    </div>

</div>
