import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from "@angular/forms";
import {SocialNetworkService} from "./social-network-dialog.service";
import {SocialNetworkModel, TypeSocialNetwork} from "../../model/social-network.model";

@Component({
    selector: 'save-social-network-dialog',
    templateUrl: './social-network-dialog.component.html',
    styleUrls: ['./social-network-dialog.scss']
})
export class SocialNetworkDialog {
    url: string;
    typeSocialNetwork: TypeSocialNetwork;
    socialNetworkService: SocialNetworkService;
    saveForm: FormGroup;

    constructor(public _dialogRef: MatDialogRef<SocialNetworkDialog>, private _formBuilder: FormBuilder) {
        this.saveForm = this.createForm();
    }

    private createForm(): FormGroup {
        return this._formBuilder.group({
            url: [''],
            typeSocialNetwork: [''],
        });
    }

    salvar(): void {
        let socialNetwork = new SocialNetworkModel();
        socialNetwork.typeSocialNetwork = this.typeSocialNetwork;
        socialNetwork.url = this.url;
        this.socialNetworkService.addSocialNetwork(socialNetwork);
        this.close();
    }

    close(): void {
        this._dialogRef.close();
    }
}
