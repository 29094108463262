<div id="categorie" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <div class="center">

        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
             fxLayoutAlign.gt-sm="space-between center">

            <div class="logo mb-24 mb-md-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    category
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Categorias
                </span>
            </div>

            <div class="search-wrapper mr-20 ml-20">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <mat-icon matTooltip="Pesquisa avançada" (click)="openFilter()">filter_list_sharp</mat-icon>
                    <input placeholder="Pesquisar..." [(ngModel)]="searchText" (keydown.enter)="search()">
                    <mat-icon *ngIf="searchText.length > 0" (click)="clear()">clear</mat-icon>
                </div>
            </div>

            <div fxLayoutAlign="end center">
                <button mat-raised-button class="fuse-white mt-24 mt-md-0" (click)="search()">
                    <mat-icon>search</mat-icon>
                    <span>Pesquisar</span>
                </button>

                <button mat-raised-button class="fuse-white ml-16 mt-24 mt-md-0"
                        matTooltip="Novo" [routerLink]="'/view/categorie/new'">
                    <mat-icon>add</mat-icon>
                    <span>Novo</span>
                </button>
            </div>
        </div>

        <div class="content-card mat-elevation-z8">

            <mat-table class="categorie-table" [dataSource]="dataSource" [@animateStagger]="{value:'50'}" matSort
                       fusePerfectScrollbar>

                <mat-header-row *matHeaderRowDef="columns; sticky:true"></mat-header-row>

                <mat-row *matRowDef="let categorie; columns: columns;" matRipple></mat-row>

                <ng-container matColumnDef="id">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Código</mat-header-cell>
                    <mat-cell *matCellDef="let categorie">
                        <p class="text-truncate">{{categorie.id}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="image">
                    <mat-header-cell *matHeaderCellDef>Imagem</mat-header-cell>
                    <mat-cell *matCellDef="let categorie">
                        <img [src]="getImageUrl(categorie.id)" class="image"/>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="description">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>Descrição</mat-header-cell>
                    <mat-cell *matCellDef="let categorie">
                        <p matTooltip="{{categorie.title}}" class="text-truncate">{{categorie.title}}</p>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="buttons">
                    <mat-header-cell *matHeaderCellDef fxLayoutAlign="center center">
                        <span>Opções</span>
                    </mat-header-cell>

                    <mat-cell *matCellDef="let categorie">
                        <div fxFlex="row" fxLayoutAlign="center center">

                            <button mat-mini-fab class="blue-600 icon" matTooltip="Editar"
                                    [routerLink]="'/view/categorie/'+categorie.id+''">
                                <mat-icon [inline]="true" class="blank">edit</mat-icon>
                            </button>

                            <button mat-mini-fab class="red icon" matTooltip="Deletar"
                                    (click)="delete(categorie.id)">
                                <mat-icon [inline]="true" class="blank">delete</mat-icon>
                            </button>
                        </div>
                    </mat-cell>
                </ng-container>

            </mat-table>

            <mat-paginator
                    fxLayoutAlign="center center"
                    [length]="lengthItems"
                    [pageSize]="10"
                    [showFirstLastButtons]="true"
                    [pageSizeOptions]="[10, 25, 50, 75, 100]">
            </mat-paginator>

        </div>
    </div>
</div>
