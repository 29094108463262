<div id="shop" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <div class="center">

        <div class="header accent" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-0 mr-sm-16" (click)="close()" [routerLink]="'/view/shop'">
                    <mat-icon style="color: white">arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">

                    <div fxLayout="row" fxLayoutAlign="start center" class="mb-12">
                        <mat-icon class="secondary-text s-24">home</mat-icon>
                        <mat-icon class="secondary-text s-24">chevron_right</mat-icon>
                        <div class="subtitle secondary-text">
                            <span>Lojas</span>
                        </div>
                        <mat-icon class="secondary-text s-24">chevron_right</mat-icon>
                        <div class="subtitle secondary-text">
                            <span>{{this.isUpdate ? 'Editar Loja' : 'Salvar Loja'}}</span>
                        </div>
                    </div>

                    <div class="h2" *ngIf="this.isUpdate">
                        # {{shop.id}} - {{shop.title}}
                    </div>

                    <div class="logo mb-24 mb-md-0" *ngIf="!this.isUpdate"
                         fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="logo-icon s-32 mr-16"
                                  [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                            business
                        </mat-icon>
                        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                            Preencha os dados
                        </span>
                    </div>

                </div>
            </div>

            <button mat-raised-button class="fuse-white mt-24 mt-md-0" (click)="update(shop)"
                    *ngIf="this.isUpdate" [disabled]="saveForm.invalid">
                <span>ATUALIZAR</span>
            </button>

            <button mat-raised-button class="fuse-white mt-24 mt-md-0"
                    (click)="save(shop)" *ngIf="!this.isUpdate" [disabled]="saveForm.invalid">
                <span>SALVAR</span>
            </button>

        </div>

        <div class="content-card">

            <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">

                <form fxLayout="column" class="w-100-p" fxLayoutAlign="start" fxFlex="1 0 auto" name="saveForm"
                      [formGroup]="saveForm">

                    <div class="tab-content p-24" fusePerfectScrollbar>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="100">
                                <mat-label>Nome da Loja</mat-label>
                                <input matInput placeholder="Nome da Loja"
                                       name="name"
                                       formControlName="name"
                                       [(ngModel)]="shop.name"
                                       required>
                                <mat-icon matSuffix style="color: gray;">title</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Fundação</mat-label>
                                <input matInput
                                       name="fundation"
                                       formControlName="fundation"
                                       [(ngModel)]="shop.fundation"
                                       autocomplete="on"
                                       [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="40">
                                <mat-label>Usuário</mat-label>
                                <mat-select [(ngModel)]="shop.user"
                                            [compareWith]="compareID"
                                            formControlName="user"
                                            placeholder="Usuário" required>
                                    <mat-select-filter [array]="filteredUsers" placeholder="Pesquisar..."
                                                       (input)="filterUser($event.target.value)"></mat-select-filter>
                                    <mat-option *ngFor="let user of filteredUsers"
                                                [value]="user">{{user.id}} - {{user.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="40">
                                <mat-label>Status</mat-label>
                                <mat-select [(ngModel)]="shop.status" placeholder="Status"
                                            [ngModelOptions]="{standalone: true}">
                                    <mat-option [value]="'EM_ANALISE'">Em Análise</mat-option>
                                    <mat-option [value]="'LIBERADO'">Liberado</mat-option>
                                    <mat-option [value]="'REJEITADO'">Rejeitado</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Dia da reunião</mat-label>
                                <mat-select [(ngModel)]="meetingDay" placeholder="Dia da reunião"
                                            [ngModelOptions]="{standalone: true}">
                                    <mat-option [value]="'Segunda-feira'">Segunda-feira</mat-option>
                                    <mat-option [value]="'Terça-feira'">Terça-feira</mat-option>
                                    <mat-option [value]="'Quarta-feira'">Quarta-feira</mat-option>
                                    <mat-option [value]="'Quinta-feira'">Quinta-feira</mat-option>
                                    <mat-option [value]="'Sexta-feira'">Sexta-feira</mat-option>
                                    <mat-option [value]="'Sábado'">Sábado</mat-option>
                                    <mat-option [value]="'Domingo'">Domingo</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Horário da reunião</mat-label>
                                <mat-select [(ngModel)]="meetingTime" placeholder="Horário da reunião"
                                            [ngModelOptions]="{standalone: true}">
                                    <mat-option [value]="'00:00'">00:00</mat-option>
                                    <mat-option [value]="'01:00'">01:00</mat-option>
                                    <mat-option [value]="'02:00'">02:00</mat-option>
                                    <mat-option [value]="'03:00'">03:00</mat-option>
                                    <mat-option [value]="'04:00'">04:00</mat-option>
                                    <mat-option [value]="'05:00'">05:00</mat-option>
                                    <mat-option [value]="'06:00'">06:00</mat-option>
                                    <mat-option [value]="'07:00'">07:00</mat-option>
                                    <mat-option [value]="'08:00'">08:00</mat-option>
                                    <mat-option [value]="'09:00'">09:00</mat-option>
                                    <mat-option [value]="'10:00'">10:00</mat-option>
                                    <mat-option [value]="'11:00'">11:00</mat-option>
                                    <mat-option [value]="'12:00'">12:00</mat-option>
                                    <mat-option [value]="'13:00'">13:00</mat-option>
                                    <mat-option [value]="'14:00'">14:00</mat-option>
                                    <mat-option [value]="'15:00'">15:00</mat-option>
                                    <mat-option [value]="'16:00'">16:00</mat-option>
                                    <mat-option [value]="'17:00'">17:00</mat-option>
                                    <mat-option [value]="'18:00'">18:00</mat-option>
                                    <mat-option [value]="'19:00'">19:00</mat-option>
                                    <mat-option [value]="'20:00'">20:00</mat-option>
                                    <mat-option [value]="'21:00'">21:00</mat-option>
                                    <mat-option [value]="'22:00'">22:00</mat-option>
                                    <mat-option [value]="'23:00'">23:00</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="30">
                                <mat-label>Rito</mat-label>
                                <mat-select [(ngModel)]="shop.rito" placeholder="Ritos"
                                            [ngModelOptions]="{standalone: true}" required>
                                    <mat-option [value]="'Escocês Antigo e Aceito'">Escocês Antigo e Aceito</mat-option>
                                    <mat-option [value]="'Adonhiramita'">Adonhiramita</mat-option>
                                    <mat-option [value]="'Schröder'">Schröder</mat-option>
                                    <mat-option [value]="'Moderno'">Moderno</mat-option>
                                    <mat-option [value]="'Ritual de Emulação'">Ritual de Emulação</mat-option>
                                    <mat-option [value]="'York'">York</mat-option>
                                    <mat-option [value]="'Brasileiro'">Brasileiro</mat-option>
                                    <mat-option [value]="'Escocês Retificado'">Escocês Retificado</mat-option>
                                    <mat-option [value]="'Memphis-Misraïm'">Memphis-Misraïm</mat-option>
                                    <mat-option [value]="'Joanita'">Joanita</mat-option>
                                </mat-select>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="30">
                                <mat-label>Potência</mat-label>
                                <input matInput placeholder="Potência"
                                       name="power"
                                       formControlName="power"
                                       [(ngModel)]="shop.power"
                                       required>
                                <mat-icon matSuffix style="color: gray;">title</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="50">
                                <mat-label>Imagem</mat-label>
                                <div fxLayout="row" fxLayoutAlign="start center">
                                    <img [src]="'data:' + imageContentType + ';base64,' + image"
                                         *ngIf="this.image != null" class="avatar"/>
                                    <img [src]="getImageUrl(shop.id)"
                                         *ngIf="shop.id != undefined && !isClearImage" class="avatar"/>

                                    <button mat-icon-button matPrefix *ngIf="nomeArquivo == null"
                                            (click)="f_input.click()">
                                        <mat-icon>attach_file</mat-icon>
                                        Selecione...
                                    </button>

                                    <input type="text text-truncate" readonly matInput
                                           formControlName="nomeArquivo" name="nomeArquivo"/>

                                    <input
                                            type="file"
                                            accept="image/png"
                                            hidden
                                            #f_input
                                            (change)="uploadDocument($event)"
                                    />

                                    <button mat-icon-button matSuffix *ngIf="nomeArquivo != null"
                                            (click)="clearFile(); f_input.value=null">
                                        <mat-icon>clear</mat-icon>
                                    </button>
                                </div>
                                <mat-error *ngIf="saveForm.get('image').hasError('maxContentSize')">
                                    O tamanho total não deve exceder {{maxSize}}MB
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="50">
                                <mat-label>Contatos</mat-label>
                                <mat-select multiple [compareWith]="compareID" [(ngModel)]="shop.contacts"
                                            formControlName="contacts" placeholder="Contatos">
                                    <mat-option *ngFor="let contact of contacts"
                                                [value]="contact">{{contact.pronounTreatment}} {{contact.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <button mat-raised-button class="mr-12 mb-20"
                                    style="align-content: center; background-color: #960210 !important; color: white !important;"
                                    (click)="openContact()" fxFlex="9">
                                <span>Adicionar</span>
                            </button>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>CEP</mat-label>
                                <input matInput placeholder="CEP"
                                       name="zipCode"
                                       maxlength="10"
                                       formControlName="zipCode"
                                       (keyup)="onKeyUp($event)"
                                       [(ngModel)]="shop.address.zipCode"
                                       mask="00000-000">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="65">
                                <mat-label>Endereço</mat-label>
                                <input matInput placeholder="Endereço"
                                       name="street"
                                       formControlName="street"
                                       [(ngModel)]="shop.address.street">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" fxFlex="15">
                                <mat-label>Número</mat-label>
                                <input matInput placeholder="Número"
                                       name="number"
                                       formControlName="number"
                                       [(ngModel)]="shop.address.number"
                                >
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                        </div>

                        <div fxLayout="row" fxLayoutAlign="start center">
                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Estado</mat-label>
                                <mat-select [(ngModel)]="shop.address.state" formControlName="state"
                                            placeholder="Estado">
                                    <mat-option [value]="">Nenhum</mat-option>
                                    <mat-option *ngFor="let state of states"
                                                [value]="state.acronym">{{state.name}}</mat-option>
                                </mat-select>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Cidade</mat-label>
                                <input matInput placeholder="Cidade"
                                       name="city"
                                       formControlName="city"
                                       [(ngModel)]="shop.address.city">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="20">
                                <mat-label>Bairro</mat-label>
                                <input matInput placeholder="Bairro"
                                       name="district"
                                       formControlName="district"
                                       [(ngModel)]="shop.address.district">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                                <mat-error>
                                    Campo obrigatório.
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" floatLabel="always" fxFlex="40">
                                <mat-label>Complemento</mat-label>
                                <input matInput
                                       placeholder="Complemento"
                                       formControlName="complement"
                                       [(ngModel)]="shop.address.complement"
                                       name="complement">
                                <mat-icon matSuffix style="color: gray;">home</mat-icon>
                            </mat-form-field>
                        </div>

                    </div>

                </form>

            </div>

        </div>

    </div>

</div>
