<div>
    <div class="logo mb-20" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="90">
            <span class="logo-text h1">Aplicar Filtros</span>
        </div>
        <div fxFlex="10">
            <button mat-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="card-divider mb-0"></div>

    <div fxLayout="column">
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Status</mat-label>
                <mat-select [(ngModel)]="status" placeholder="Status"
                            [ngModelOptions]="{standalone: true}">
                    <mat-option [value]="'EM_ANALISE'">Free - line</mat-option>
                    <mat-option [value]="'LIBERADO'">Liberado com banner</mat-option>
                    <mat-option [value]="'REJEITADO'">Rejeitado</mat-option>
                    <mat-option [value]="'CARROSSEL'">Carrossel - índice</mat-option>
                    <mat-option [value]="'PREMIUM'">Premium(Carrossel - índice)</mat-option>
                    <mat-option [value]="'CRIADO_APP'">Criado - app</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="mr-12" fxFlex="33">
                <mat-label>Categorias</mat-label>
                <mat-select multiple
                            [(ngModel)]="categorie"
                            [compareWith]="compareID"
                            formControlName="categorie"
                            placeholder="Categorias" required>
                    <mat-option *ngFor="let categorie of categories"
                                [value]="categorie">{{categorie.title}}</mat-option>
                </mat-select>
            </mat-form-field>

            <button mat-button class="button-filter" (click)="executeFilter()">
                Filtrar
            </button>
        </form>
    </div>
</div>
