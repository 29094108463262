import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {createRequestOption} from '../../util/request-util';
import {FilterSearch} from '../model/filtro.model';
import {ShopModel} from "../model/shop.model";

type EntityResponseType = HttpResponse<ShopModel>;
type EntityArrayResponseType = HttpResponse<ShopModel[]>;

@Injectable({providedIn: 'root'})
export class ShopService {
    public resourceUrl = environment.url + 'api/v1/shops';

    constructor(protected http: HttpClient) {
    }

    create(event: ShopModel): Observable<EntityResponseType> {
        return this.http.post<ShopModel>(`${this.resourceUrl}`, event, {observe: 'response'});
    }

    update(event: ShopModel): Observable<EntityResponseType> {
        return this.http.put<ShopModel>(this.resourceUrl, event, {observe: 'response'});
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<ShopModel>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    query(request?: any, filters: Array<FilterSearch> = []): Observable<EntityArrayResponseType> {
        let options = createRequestOption(request);
        filters.forEach(value => options = options.append(value.key, value.value));
        return this.http.get<ShopModel[]>(`${this.resourceUrl}/search`, {params: options, observe: 'response'});
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    deleteImage(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/image/${id}`, {observe: 'response'});
    }
}
