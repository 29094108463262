import {NgModule} from '@angular/core';
import {SharedModule} from '../../util/shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {ShopService} from './shop.service';
import {ShopComponent} from './shop.component';
import {RouterModule} from '@angular/router';
import {ShopSaveComponent} from "./save/shop-save.component";
import {FilterShopDialog} from "./filter/filter-shop-dialog.component";
import {MatSelectFilterModule} from "mat-select-filter";

const routes = [
    {
        path: 'shop',
        component: ShopComponent
    },
    {
        path     : 'shop/:id',
        component: ShopSaveComponent
    }
];

@NgModule({
    declarations: [
        ShopComponent,
        ShopSaveComponent,
        FilterShopDialog,
    ],
    exports: [
        ShopComponent,
        ShopSaveComponent,
        FilterShopDialog,
    ],
    entryComponents: [
        FilterShopDialog,
    ],
    providers: [
        ShopService
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgxMaskModule,
        MatSelectFilterModule
    ]
})
export class ShopModule { }
