export class DashboardModel {
    userLast30Days: number;
    totalUsers: number;
    announcementLast30Days: number;
    totalAnnouncement: number;
    totalAnnouncementCarrossel: number;
    totalAnnouncementPremium: number;
    totalAnnouncementFreeLine: number;
    totalAnnouncementReleased: number;
    totalAnnouncementRejected: number;
    totalAnnouncementByApp: number;
    totalEvents: number;
    totalShops: number;
}
