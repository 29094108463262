import {StatusEvent} from "./event.model";

export enum TypeSocialNetwork {
    FACEBOOK = "FACEBOOK",
    INSTAGRAM = "INSTAGRAM",
    TWITTER = "TWITTER",
    LINKEDIN = "LINKEDIN",
    TIKTOK = "TIKTOK",
    YOUTUBE = "YOUTUBE",
    SITE = "SITE",
    OTHERS = "OTHERS",
}

export class SocialNetworkModel {
    public id?: number;
    public typeSocialNetwork?: TypeSocialNetwork;
    public url?: string;
}
