<div id="dashboard" class="page-layout carded fullwidth inner-scroll">

    <div class="top-bg accent"></div>

    <div class="center">

        <div class="header accent" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row"
             fxLayoutAlign.gt-sm="space-between center">

            <div class="logo mb-24 mb-md-0"
                 fxLayout="row" fxLayoutAlign="start center">
                <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                    dashboard
                </mat-icon>
                <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    Dashboard
                </span>
            </div>
        </div>

        <div class="content">
            <div class="widget-group mr-lg-32" fxLayout="row wrap" fxFlex="100" *fuseIfOnDom>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Usuário - Últimos 30 Dias</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                               +{{model.userLast30Days}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>


                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Total de Usuários</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                               {{model.totalUsers}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Anúncios - Últimos 30 Dias</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                               +{{model.announcementLast30Days}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Anúncio - Criado - app</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                              {{model.totalAnnouncementByApp}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Total de Anúncios</div>
                        </div>

                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72">
                                   {{model.totalAnnouncement}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Anúncio - Liberado com banner</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                               {{model.totalAnnouncementReleased}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Anúncio - Carrossel - índice</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                              {{model.totalAnnouncementCarrossel}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Anúncio - Premium(Carrossel - índice)</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                              {{model.totalAnnouncementPremium}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>


                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Anúncio - Free - line</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                               {{model.totalAnnouncementFreeLine}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Anúncio - Rejeitados</div>
                        </div>
                        <div *ngIf="loading"
                             style=" display: flex; justify-content: center; padding-top: 10px; padding-bottom: 10px;"
                             class="loadingIcon">
                            <mat-progress-spinner
                                    [color]="'primary'"
                                    [mode]="'indeterminate'"
                                    [diameter]="35"
                            ></mat-progress-spinner>
                        </div>
                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72" *ngIf="!loading">
                             {{model.totalAnnouncementRejected}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>


                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Total de Eventos</div>
                        </div>

                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72">
                                  {{model.totalEvents}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>


                <fuse-widget class="widget" fxLayout="column" fxFlex="100" fxFlex.gt-xs="50" fxFlex.gt-md="25">

                    <div class="fuse-widget-front">
                        <div class="pl-16 pr-8 py-16 h-52 mt-20" fxLayout="row" fxLayoutAlign="center center">
                            <div class="font-size-20">Total de Lojas</div>
                        </div>

                        <div class="pt-8 pb-32" fxLayout="column" fxLayoutAlign="center center">
                            <span class="gray font-size-60 line-height-72">
                                  {{model.totalShops}}
                            </span>
                        </div>
                    </div>

                </fuse-widget>

            </div>
        </div>
    </div>
</div>
