import {Injectable} from '@angular/core';
import Swal from 'sweetalert2';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class UtilMessage {

    public success(title: string, message: string = ''): void {
        Swal.fire({
            icon: 'success',
            title: title,
            text: message,
            showConfirmButton: false,
            timer: 2000
        });
    }

    public error(error: HttpErrorResponse): void {
        const message = this.getErrorMessage(error);
        Swal.fire('Operação não permitida!', message, 'error');
    }

    public getErrorMessage(error: HttpErrorResponse): string {
        let message = '';
        if (error.error && error.error.violations) {
            error.error.violations.forEach(violation => {
                message += `<span>${violation.message}</span><br/>`;
            });
        } else if (error.error.detail) {
            message = error.error.detail;
        } else {
            message = 'Ocorreu um erro desconhecido. Por favor, tente novamente.';
        }

        return message;
    }


    public warning(title: string, message: string): void {
        Swal.fire(title, message, 'warning');
    }
}
