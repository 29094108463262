import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {TranslateModule} from '@ngx-translate/core';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';

import {fuseConfig} from 'app/fuse-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {MainModule} from './main/main.module';
import {AuthGuard} from './main/auth/auth.guard';
import {JwtInterceptor} from './util/jwt.interceptor';
import {NgxMaskModule} from 'ngx-mask';
import {MAT_DATE_FORMATS} from '@angular/material/core';
import {ZipCodeService} from './util/zip-code.service';
import {UtilMessage} from './util/util-message';
import {LoaderInterceptor} from './util/loader.interceptor';
import {ErrorInterceptor} from './util/error.interceptor';
import config from 'devextreme/core/config';
import {DevExtremeModule} from 'devextreme-angular';

config({defaultCurrency: 'BRL'});

export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    },
};

const appRoutes: Routes = [
    {
        path: 'view',
        canActivate: [AuthGuard],
        loadChildren: './main/main.module#MainModule'
    },
    {
        path: '**',
        redirectTo: 'login'
    }
];

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatProgressSpinnerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // Dev Express
        DevExtremeModule,

        // App modules
        LayoutModule,
        MainModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
        ZipCodeService,
        UtilMessage
    ]
})
export class AppModule {
}
