import {NgModule} from '@angular/core';
import {SharedModule} from '../../util/shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {EventService} from './event.service';
import {EventComponent} from './event.component';
import {RouterModule} from '@angular/router';
import {EventSaveComponent} from "./save/event-save.component";
import {FilterEventDialog} from "./filter/filter-event-dialog.component";

const routes = [
    {
        path: 'event',
        component: EventComponent
    },
    {
        path     : 'event/:id',
        component: EventSaveComponent
    }
];

@NgModule({
    declarations: [
        EventComponent,
        EventSaveComponent,
        FilterEventDialog
    ],
    exports: [
        EventComponent,
        EventSaveComponent,
        FilterEventDialog
    ],
    entryComponents: [
        FilterEventDialog
    ],
    providers: [
        EventService
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgxMaskModule
    ]
})
export class EventModule { }
