import {NgModule} from '@angular/core';
import {SharedModule} from '../../util/shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {CategorieService} from './categorie.service';
import {CategorieComponent} from './categorie.component';
import {RouterModule} from '@angular/router';
import {CategorieSaveComponent} from './save/categorie-save.component';
import {FilterCategorieDialog} from './filter/filter-categorie-dialog.component';

const routes = [
    {
        path: 'categorie',
        component: CategorieComponent
    },
    {
        path     : 'categorie/:id',
        component: CategorieSaveComponent
    }
];

@NgModule({
    declarations: [
        CategorieComponent,
        CategorieSaveComponent,
        FilterCategorieDialog
    ],
    exports: [
        CategorieComponent,
        CategorieSaveComponent,
        FilterCategorieDialog
    ],
    entryComponents: [
        FilterCategorieDialog
    ],
    providers: [
        CategorieService
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgxMaskModule
    ]
})
export class CategorieModule { }
