import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FilterSearch} from "../model/filtro.model";
import {Observable} from "rxjs";
import {createRequestOption} from "../../util/request-util";
import {AnnouncementModel} from "../model/announcement.model";
import {DashboardModel} from "../model/dashboard.model";
type EntityResponseType = HttpResponse<DashboardModel>;

@Injectable({providedIn: 'root'})
export class DashboardService {
    public resourceUrl = environment.url + 'api/v1';

    constructor(protected http: HttpClient) {
    }

    query(): Observable<EntityResponseType> {
        return this.http.get<DashboardModel>(`${this.resourceUrl}/dashboard`, {observe: 'response'});
    }
}
