<div id="login" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">

    <div id="login-intro" fxFlex fxHide fxShow.gt-xs>

        <div class="logo" [@animate]="{value:'*',params:{scale:'0.1'}}">
            <img src="assets/images/logos/chibarrogrei.png">
        </div>

        <div class="description" [@animate]="{value:'*',params:{delay:'100ms',y:'25px'}}">
            Sistema de Índice Comercial
        </div>

    </div>

    <div id="login-form-wrapper" fusePerfectScrollbar
         [@animate]="{value:'*',params:{delay:'300ms',x:'100%'}}">

        <div id="login-form">
            <div class="title text-center">Faça login na sua conta</div>

            <form name="loginForm" [formGroup]="loginForm" novalidate>
                <mat-form-field appearance="outline">
                    <mat-label>Login</mat-label>
                    <input matInput formControlName="login">
                    <mat-icon matSuffix class="secondary-text">person</mat-icon>
                    <mat-error>
                        Login é obrigatório
                    </mat-error>
                    <mat-error
                            *ngIf="!loginForm.get('login').hasError('required') &&
                                loginForm.get('login').hasError('login')">
                        Por favor insira um login válido
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Senha</mat-label>
                    <input matInput type="password" formControlName="password"
                           [type]="hidePassword ? 'password' : 'text'">
                    <mat-icon class="hidePasswordIcon" matSuffix (click)="hidePassword = !hidePassword"
                              [attr.aria-pressed]="hidePassword">
                        <mat-icon class="secondary-text">{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-icon>
                    <mat-error>
                        Senha é obrigatório
                    </mat-error>
                </mat-form-field>

                <button (click)="login()" mat-raised-button class="submit-button" aria-label="LOGIN"
                        [disabled]="loginForm.invalid">
                    Entrar
                </button>
            </form>
        </div>
    </div>
</div>
