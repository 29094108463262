import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {createRequestOption} from '../../util/request-util';
import {CategorieModel} from '../model/categorie.model';
import {FilterSearch} from '../model/filtro.model';

type EntityResponseType = HttpResponse<CategorieModel>;
type EntityArrayResponseType = HttpResponse<CategorieModel[]>;

@Injectable({providedIn: 'root'})
export class CategorieService {
    public resourceUrl = environment.url + 'api/v1/categories';

    constructor(protected http: HttpClient) {
    }

    create(categorie: CategorieModel): Observable<EntityResponseType> {
        return this.http.post<CategorieModel>(this.resourceUrl, categorie, {observe: 'response'});
    }

    update(categorie: CategorieModel): Observable<EntityResponseType> {
        return this.http.put<CategorieModel>(this.resourceUrl, categorie, {observe: 'response'});
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<CategorieModel>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    query(request?: any, filters: Array<FilterSearch> = []): Observable<EntityArrayResponseType> {
        let options = createRequestOption(request);
        filters.forEach(value => options = options.append(value.key, value.value));
        return this.http.get<CategorieModel[]>(`${this.resourceUrl}/search`, {params: options, observe: 'response'});
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    findAll(): Observable<EntityArrayResponseType> {
        return this.http.get<CategorieModel[]>(`${this.resourceUrl}`, {observe: 'response'});
    }
}
