import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {UserComponent} from './user.component';
import {UserService} from './user.service';
import {SharedModule} from '../../util/shared.module';
import {NgxMaskModule} from 'ngx-mask';
import {FilterUserDialog} from './filter/filter-user-dialog';

const routes = [
    {
        path: 'user',
        component: UserComponent
    }
];

@NgModule({
    declarations: [
        UserComponent,
        FilterUserDialog
    ],
    providers: [
        UserService
    ],
    entryComponents: [
        FilterUserDialog
    ],
    imports: [
        RouterModule.forChild(routes),
        SharedModule,
        NgxMaskModule
    ],
    exports: [
        UserComponent,
        FilterUserDialog
    ]
})
export class UserModule { }
