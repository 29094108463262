import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {Location} from '@angular/common';
import {Subject} from 'rxjs';
import {fuseAnimations} from '@fuse/animations';
import {ActivatedRoute} from '@angular/router';
import {UtilMessage} from '../../../util/util-message';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AnnouncementModel} from '../../model/announcement.model';
import {FileInput, FileInputComponent, FileValidator} from 'ngx-material-file-input';
import {AnnouncementService} from '../announcement.service';
import {CategorieModel} from "../../model/categorie.model";
import {CategorieService} from "../../categorie/categorie.service";
import {User} from "../../model/user.model";
import {UserService} from "../../user/user.service";
import {ContactModel} from "../../model/contact.model";
import {State} from "../../model/state.model";
import {ZipCodeService} from "../../../util/zip-code.service";
import {MatDialog} from "@angular/material/dialog";
import {ContactDialog} from "../contact/contact-dialog.component";
import {AddressModel} from "../../model/address.model";
import {ContactService} from "../contact/contact-dialog.service";
import {SocialNetworkModel, TypeSocialNetwork} from "../../model/social-network.model";
import {SocialNetworkService} from "../social-network/social-network-dialog.service";
import {SocialNetworkDialog} from "../social-network/social-network-dialog.component";
import {FileModel, MimeHelper} from "../../model/file.model";

@Component({
    selector: 'edit-announcement',
    templateUrl: './announcement-save.component.html',
    styleUrls: ['./announcement-save.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AnnouncementSaveComponent implements OnInit, OnDestroy, ContactService, SocialNetworkService {

    announcement: AnnouncementModel;
    states: Array<State>;
    categories: Array<CategorieModel> = [];
    contacts: Array<ContactModel> = [];
    socialNetworks: Array<SocialNetworkModel> = [];
    users: Array<User> = [];
    isUpdate = true;
    saveForm: FormGroup;
    image: string;
    isClearImage = false;
    imageContentType: string;
    nomeArquivo: string;
    private _unsubscribeAll: Subject<any>;
    private maxSize = 2;
    filteredUsers: Array<User> = [];
    filteredCategories: Array<CategorieModel> = [];

    constructor(private _location: Location,
                private _route: ActivatedRoute,
                private dialog: MatDialog,
                private _message: UtilMessage,
                private _formBuilder: FormBuilder,
                private _categorieService: CategorieService,
                private _zipCodeService: ZipCodeService,
                private _userService: UserService,
                private _announcementService: AnnouncementService) {
        this.announcement = new AnnouncementModel();
        this.announcement.address = new AddressModel();
        this._unsubscribeAll = new Subject();
        this._categorieService.findAll().toPromise().then(value => {
            this.categories = value.body
            this.filteredCategories = this.categories = value.body;
        });
        this._userService.findAll().toPromise().then(value => {
            this.users = value.body;
            this.filteredUsers = this.users;
        });
        this.states = State.getStates();
    }

    ngOnInit(): void {
        this._route.params.subscribe(params => {
            const id = params['id'];
            this.isUpdate = id !== 'new';
            this.loadAnnouncement(id);
        });
        this.saveForm = this.createForm();
    }

    onKeyUp(zipCode): void {
        if (zipCode !== null && zipCode.target.value.length === 9) {
            this._zipCodeService.get(this.announcement.address.zipCode).toPromise().then(response => {
                this.announcement.address.street = response.logradouro;
                this.announcement.address.district = response.bairro;
                this.announcement.address.city = response.localidade;
                this.announcement.address.state = response.uf;
            });
        }
    }

    private createForm(): FormGroup {
        return this._formBuilder.group({
            code: [''],
            title: [''],
            date: [''],
            categorie: [''],
            contacts: [''],
            socialNetworks: [''],
            user: [''],
            image: ['', [FileValidator.maxContentSize(this.maxSize * 1048576)]],
            description: [''],
            district: [''],
            zipCode: [''],
            street: [''],
            number: [''],
            state: [''],
            nomeArquivo: [''],
            imageContentType: [null],
            city: [''],
            complement: [''],
        });
    }

    private loadAnnouncement(id): void {
        if (this.isUpdate) {
            this._announcementService.find(id).toPromise().then(result => {
                this.announcement = result.body;
                this.contacts = this.announcement.contacts;
                this.socialNetworks = this.announcement.socialNetworks;
                this.image = this.announcement.image;
                this.imageContentType = this.announcement.contentType;
                this.nomeArquivo = this.announcement.nomeArquivo;
                this.saveForm.patchValue(this.announcement);
            });
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    save(announcement: AnnouncementModel): void {
        announcement.image = this.image;
        announcement.nomeArquivo = this.nomeArquivo;
        announcement.contentType = this.imageContentType;
        this._announcementService.create(announcement).toPromise()
            .then((value) => {
                this._message.success('Registro salvo com sucesso!');
                this.close();
            }).catch((error) => this._message.error(error));
    }

    update(announcement: AnnouncementModel): void {
        announcement.image = this.image;
        announcement.nomeArquivo = this.nomeArquivo;
        announcement.contentType = this.imageContentType;
        this._announcementService.update(announcement).toPromise()
            .then(() => {
                this._message.success('Registro atualizado com sucesso!');
                this.close();
            }).catch((error) => this._message.error(error));
    }

    close(): void {
        this._location.back();
    }

    uploadDocument(value): void {
        const file = value.target.files[0];
        const ext = FileModel.getFileExtension(file.name);
        const m = MimeHelper.findByExtension(ext);

        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = () => {
            this.imageContentType = m.documentoContentType;
            this.nomeArquivo = file.name;
            this.image = btoa(reader.result.toString());
            this.saveForm.patchValue({
                'nomeArquivo': file.name,
                'image': this.image,
                'imageContentType': m.documentoContentType
            });
        };
    }

    clearFile() {
        this.image = null;
        this.isClearImage = true;
        this.nomeArquivo = null;
        this.imageContentType = null;
        this.saveForm.patchValue({
            'nomeArquivo': null,
            'image': null,
            'imageContentType': null
        });
    }

    compareID(one: any, two: any): boolean {
        return one && two ? one.id === two.id : one === two;
    }

    addContact(contact: ContactModel) {
        this.contacts.push(contact);
    }

    addSocialNetwork(social: SocialNetworkModel) {
        this.socialNetworks.push(social);
    }

    openContact(): void {
        const dialogRef = this.dialog.open(ContactDialog, {
            width: '30%',
            disableClose: true
        });
        dialogRef.componentInstance.contactService = this;
    }

    openSocialNetwork(): void {
        const dialogRef = this.dialog.open(SocialNetworkDialog, {
            width: '30%',
            disableClose: true
        });
        dialogRef.componentInstance.socialNetworkService = this;
    }

    filterUser(value: string): void {
        this.filteredUsers = this.users.filter((user) =>
            user.name.toLowerCase().includes(value.toLowerCase()) || user.id == Number(value)
        );
    }

    filterCategorie(value: string): void {
        this.filteredCategories = this.categories.filter((item) =>
            item.title.toLowerCase().includes(value.toLowerCase()) || item.id == Number(value)
        );
    }

    getTypeSocialNetwork(typeSocialNetwork?: TypeSocialNetwork): string {
        switch (typeSocialNetwork) {
            case TypeSocialNetwork.FACEBOOK:
                return "Facebook";
            case TypeSocialNetwork.INSTAGRAM:
                return "Instagram";
            case TypeSocialNetwork.TWITTER:
                return "Twitter";
            case TypeSocialNetwork.LINKEDIN:
                return "Linkedin";
            case TypeSocialNetwork.TIKTOK:
                return "Tiktok";
            case TypeSocialNetwork.YOUTUBE:
                return "Youtube";
            case TypeSocialNetwork.SITE:
                return "Site";
            default:
                return "Outros";
        }
    }

    getImageUrl(id: number): string {
        return `https://chibarro-grei-prod-public-assets.s3.amazonaws.com/announcements/${id}.png?v=${new Date().getTime()}`;  // Adiciona um timestamp
    }
}
