import { FuseNavigation } from '@fuse/types';
import { ROLE } from '../main/model/authority.model';

export const navigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
            {
                id: 'announcement',
                title: 'Anúncios',
                type: 'item',
                icon: 'business',
                roles: [ROLE.CHIBARROGREI,  ROLE.USER],
                url: '/view/announcement'
            },
            {
                id: 'categorie',
                title: 'Categoria',
                type: 'item',
                icon: 'category',
                roles: [ROLE.CHIBARROGREI,  ROLE.USER],
                url: '/view/categorie'
            },
            {
                id: 'event',
                title: 'Eventos',
                type: 'item',
                icon: 'event',
                roles: [ROLE.CHIBARROGREI,  ROLE.USER],
                url: '/view/event'
            },
            {
                id: 'shop',
                title: 'Lojas',
                type: 'item',
                icon: 'store',
                roles: [ROLE.CHIBARROGREI,  ROLE.USER],
                url: '/view/shop'
            },
            {
                id: 'user',
                title: 'Usuários',
                type: 'item',
                icon: 'account_box',
                roles: [ROLE.CHIBARROGREI,  ROLE.USER],
                url: '/view/user'
            },
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: 'dashboard',
                roles: [ROLE.CHIBARROGREI,  ROLE.USER],
                url: '/view/dashboard'
            }
        ]
    }
];
