import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {FuseSplashScreenService} from '../../../@fuse/services/splash-screen.service';
import {fuseAnimations} from '../../../@fuse/animations';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {AuthenticationService} from '../auth/authentication.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    hidePassword = true;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _authenticationService: AuthenticationService,
        private _fuseSplashScreenService: FuseSplashScreenService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    ngOnInit(): void {
        this.loginForm = this._formBuilder.group({
            login: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    login(): void {
        this._fuseSplashScreenService.show();
        this._authenticationService
            .login(this.loginForm.get('login').value, this.loginForm.get('password').value)
            .then(() => {
                this._fuseSplashScreenService.hide();
                this._router.navigate(['/view/dashboard']);
            }).catch(erro => {
                Swal.fire('Acesso negado!', 'Por favor, verifique os seus dados e tente novamente.', 'error');
                this._fuseSplashScreenService.hide();
            }
        );
    }

}
