import {Moment} from "moment";
import {ContactModel} from "./contact.model";
import {User} from "./user.model";
import {AddressModel} from "./address.model";

export enum StatusEvent {
    LIBERADO = 'LIBERADO',
    REJEITADO = 'REJEITADO',
    EM_ANALISE = 'EM_ANALISE'
}

export class EventModel {
    public id?: number;
    public title?: string;
    public description?: string;
    public image?: string;
    public nomeArquivo?: string;
    public contentType?: string;
    public dateBegin?: Moment;
    public dateEnd?: Moment;
    public contacts?: Array<ContactModel>;
    public user?: User;
    public status?: StatusEvent;
    public address?: AddressModel;
}
