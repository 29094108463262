import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {createRequestOption} from '../../util/request-util';
import {EventModel} from '../model/event.model';
import {FilterSearch} from '../model/filtro.model';

type EntityResponseType = HttpResponse<EventModel>;
type EntityArrayResponseType = HttpResponse<EventModel[]>;

@Injectable({providedIn: 'root'})
export class EventService {
    public resourceUrl = environment.url + 'api/v1/events';

    constructor(protected http: HttpClient) {
    }

    create(event: EventModel): Observable<EntityResponseType> {
        return this.http.post<EventModel>(this.resourceUrl, event, {observe: 'response'});
    }

    update(event: EventModel): Observable<EntityResponseType> {
        return this.http.put<EventModel>(this.resourceUrl, event, {observe: 'response'});
    }

    find(id: number): Observable<EntityResponseType> {
        return this.http.get<EventModel>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }

    query(request?: any, filters: Array<FilterSearch> = []): Observable<EntityArrayResponseType> {
        let options = createRequestOption(request);
        filters.forEach(value => options = options.append(value.key, value.value));
        return this.http.get<EventModel[]>(`${this.resourceUrl}/search`, {params: options, observe: 'response'});
    }

    delete(id: number): Observable<HttpResponse<any>> {
        return this.http.delete<any>(`${this.resourceUrl}/${id}`, {observe: 'response'});
    }
}
