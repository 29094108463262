import { Component } from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector   : 'fuse-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls  : ['./confirm-dialog.component.scss']
})
export class FuseConfirmDialogComponent
{
    public confirmMessage = '';
    public title = 'Confirmar';
    public isConfirmar = true;
    public isCancelar = true;
    public subMessage = '';
    public confirmarButton = 'Confirmar';
    public cancelarButton = 'Cancelar';

    constructor(public dialogRef: MatDialogRef<FuseConfirmDialogComponent>) {
    }
}
