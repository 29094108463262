import { HotjarUtil } from './../../util/hotjar-util';
import { DOCUMENT } from '@angular/common';
import {fuseAnimations} from '@fuse/animations';

import { AfterContentInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '../auth/authentication.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, AfterContentInit {
  userName: string = '';
  
  constructor(
    @Inject(DOCUMENT) private document: any,
    private _authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.userName = this._authenticationService.getNameCurrentUser();
  }

  ngAfterContentInit(): void {
    HotjarUtil.config(this.document, this._authenticationService);
  }
}