import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard.component';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsModule} from 'ng2-charts';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {AgmCoreModule} from '@agm/core';
import {FuseWidgetModule} from '../../../@fuse/components';
import {SharedModule} from '../../util/shared.module';
import {DashboardService} from './dashboard.service';
import {MatBadgeModule} from '@angular/material/badge';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const routes = [
    {
        path: 'dashboard',
        component: DashboardComponent
    }
];

@NgModule({
    declarations: [
        DashboardComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressSpinnerModule,

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyD81ecsCj4yYpcXSLFcYU97PvRsE_X8Bx8'
        }),
        ChartsModule,
        NgxChartsModule,

        FuseSharedModule,
        FuseWidgetModule,
        SharedModule,
        MatBadgeModule
    ],
    providers: [
        DashboardService
    ],
    exports: [
        DashboardComponent
    ]
})

export class DashboardModule {
}
