import {Component, OnInit, ViewEncapsulation} from '@angular/core';

import {DashboardService} from './dashboard.service';
import {AuthenticationService} from '../auth/authentication.service';
import {fuseAnimations} from "../../../@fuse/animations";
import {DashboardModel} from "../model/dashboard.model";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {EventModel} from "../model/event.model";
import Swal from "sweetalert2";

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {
    model: DashboardModel = new DashboardModel();

    loading: boolean = true;

    constructor(private _dashboardService: DashboardService) {
    }

    ngOnInit(): void {
        this.loadData();
    }

    loadData(): void {
        this.loading = false;
        this._dashboardService.query().subscribe(
            (res: HttpResponse<DashboardModel>) => {
                this.model = res.body;
            },
            (res: HttpErrorResponse) => Swal.fire('Erro...', res.message, 'error')
        );
    }
}
