import { Component } from '@angular/core';
import {DataUtils} from './data-util.service';
import {PdfService} from './pdf.service';

@Component({
  selector: 'pdf-component',
  templateUrl: './pdf.component.html',
  styleUrls: ['pdf.scss']
})
export class PdfComponent {

  public titulo: string;
  public pdf: string;
  public pagina: number;
  public zoom: number;
  public totalPagina: number;
  public isLoaded: boolean;
  public service: PdfService;

  constructor(private dataUtils: DataUtils) {
    this.pagina = 1;
    this.isLoaded = false;
    this.zoom = 0.8;
  }

  afterLoadComplete(pdfData: any): void {
    this.totalPagina = pdfData.numPages;
  }

  zoomOut(): void {
    this.zoom -= 0.1;
  }

  zoomIn(): void {
    this.zoom += 0.1;
  }

  nextPagina(): void {
    this.pagina++;
  }

  download(): void {
    this.dataUtils.downloadFile('application/pdf', this.pdf, this.titulo);
  }

  previunsPagina(): void {
    this.pagina--;
  }

  stop(): void {
      this.service.stop();
  }
}
