import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {ExecutorFilter} from '../../../util/executor-filter';
import {FilterSearch} from '../../model/filtro.model';

@Component({
    selector: 'filter-categirie',
    templateUrl: './filter-categorie-dialog.component.html',
    styleUrls: ['./filter-categirie-dialog.scss']
})
// tslint:disable-next-line:component-class-suffix
export class FilterCategorieDialog {

    id: number;
    description: string;
    executorFilter: ExecutorFilter;

    constructor(public _dialogRef: MatDialogRef<FilterCategorieDialog>) {
    }

    executeFilter(): void {
        const filters = new Array<FilterSearch>();
        if (this.id) {
            filters.push(new FilterSearch('id', this.id));
        }

        if (this.description) {
            filters.push(new FilterSearch('description', this.description));
        }

        this.executorFilter.executeFilter(filters);
        this.close();
    }

    close(): void {
        this._dialogRef.close();
    }
}
