import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild, ViewEncapsulation} from '@angular/core';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import Swal from 'sweetalert2';
import {merge, Subject} from 'rxjs';
import {tap} from 'rxjs/operators';
import {UtilMessage} from '../../util/util-message';
import {MatDialog} from '@angular/material/dialog';
import {ExecutorFilter} from '../../util/executor-filter';
import {FilterSearch} from '../model/filtro.model';
import {CategorieService} from './categorie.service';
import {fuseAnimations} from '../../../@fuse/animations';
import {FilterCategorieDialog} from './filter/filter-categorie-dialog.component';
import {CategorieModel} from "../model/categorie.model";

@Component({
    selector: 'categorie',
    templateUrl: './categorie.component.html',
    styleUrls: ['./categorie.component.scss'],
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class CategorieComponent implements AfterViewInit, OnDestroy, ExecutorFilter {

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('filter') filter: ElementRef;

    dataSource = new Array<CategorieModel>();
    searchText = '';
    lengthItems = 0;
    columns = ['id', 'image', 'description', 'buttons'];

    private _unsubscribeAll: Subject<any>;

    constructor(private _service: CategorieService, private _message: UtilMessage, private dialog: MatDialog) {
        this._unsubscribeAll = new Subject();
    }

    ngAfterViewInit(): void {
        this.paginator._intl.itemsPerPageLabel = 'Itens por página:';
        this.paginator.pageIndex = 0;
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
        this.search();

        merge(this.sort.sortChange, this.paginator.page).pipe(tap(() => this.loadCategiries(new Array<FilterSearch>()))).subscribe();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    loadCategiries(filters: Array<FilterSearch>): void {
        const request = {
            page: this.paginator.pageIndex,
            size: this.paginator.pageSize,
            sort: this.sort.active ? this.sort.active + ',' + this.sort.direction : 'id,asc'
        };

        this._service.query(request, filters).subscribe(
            (res: HttpResponse<CategorieModel[]>) => {
                this.lengthItems = parseInt(res.headers.get('X-Total-Count'), 10);
                this.dataSource = res.body;
            },
            (res: HttpErrorResponse) => Swal.fire('Erro...', res.message, 'error')
        );
    }

    search(): void {
        const filters = new Array<FilterSearch>();
        filters.push(new FilterSearch('search', this.searchText));
        this.loadCategiries(filters);
    }

    delete(id: number): void {
        Swal.fire({
            title: 'Você tem certeza?',
            text: 'Você não poderá reverter isso!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Excluir'
        }).then((result) => {
            if (result.isConfirmed) {
                this._service.delete(id).toPromise()
                    .then(() => {
                        Swal.fire({
                            icon: 'success',
                            title: 'Registro removido com sucesso!',
                            showConfirmButton: false,
                            timer: 2000
                        });
                        this.loadCategiries(new Array<FilterSearch>());
                    }).catch(error => Swal.fire('Erro...', 'Não foi possível remover!', 'error'));
            }
        });
    }

    clear(): void {
        this.searchText = '';
        this.search();
    }

    openFilter(): void {
        const dialogRef = this.dialog.open(FilterCategorieDialog, {
            width: '30%',
            disableClose: true
        });
        dialogRef.componentInstance.executorFilter = this;
    }

    executeFilter(filters: Array<FilterSearch>): void {
        this.loadCategiries(filters);
    }

    getImageUrl(id): string {
        return `https://chibarro-grei-prod-public-assets.s3.amazonaws.com/categories/${id}.png?v=${new Date().getTime()}`;  // Adiciona um timestamp
    }
}
