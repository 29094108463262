<div>
    <div class="logo mb-20" fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="90">
            <span class="logo-text h1">Aplicar Filtros</span>
        </div>
        <div fxFlex="10">
            <button mat-button (click)="close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="card-divider mb-0"></div>

    <div fxLayout="column">
        <form fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Código</mat-label>
                <input matInput placeholder="Código" [(ngModel)]="id" [ngModelOptions]="{standalone: true}">
                <mat-icon matSuffix style="color: gray;">vpn_key</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" fxFlex="100">
                <mat-label>Descrição</mat-label>
                <input matInput placeholder="Descrição" [(ngModel)]="description" [ngModelOptions]="{standalone: true}">
                <mat-icon matSuffix style="color: gray;">description</mat-icon>
                <mat-error>
                    Campo obrigatório.
                </mat-error>
            </mat-form-field>

            <button mat-button class="button-filter" (click)="executeFilter()">
                Filtrar
            </button>
        </form>
    </div>
</div>
